import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import DrawOutlinedIcon from "@mui/icons-material/DrawOutlined";
import { useNavigate } from "react-router-dom";
function SubHeader(props) {
  const { classes } = props;
  const navigate = useNavigate();
  return (
    <Box className={classes.subHeaderSec}>
      <Box className={classes.subHeaderLeft}>
        <Box
          onClick={() => {
            navigate(-1);
          }}
          className={classes.subHeaderBack}
        >
          <ArrowBackRoundedIcon />
        </Box>
        <Typography>710 Cox Road, Roswell, GA 30075</Typography>
      </Box>
      <Box className={classes.subHeaderCenter}>Tab area </Box>
      <Box className={classes.subHeaderRight}>
        <Button
          className={classes.headerButton}
          size="small"
          variant="contained"
          endIcon={<SendOutlinedIcon sx={{ ml: "12px" }} />}
        >
          Send
        </Button>
        <Button
          className={classes.headerButton}
          size="small"
          variant="contained"
          endIcon={<DrawOutlinedIcon sx={{ ml: "12px" }} />}
        >
          Sign
        </Button>
      </Box>
    </Box>
  );
}

export default SubHeader;
