import React, { useContext, useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { LeadsCustomFilters } from "../filters/LeadsCustomFilters";
import { CustomFilterableDataGrid } from "../../../../components/uiElements/customFilterabeDataGrid/CustomFilterableDataGrid";
import { LeadsCustomToolbar } from "../filters/LeadsCustomToolbar";
import { SidebarAllFilter } from "../filters/SidebarAllFilter";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { getColumns } from "./constants";
import {
  getLeadsIdsAfterFilter,
  setPeopleColumnsOrder,
  setPeopleColumnsVisibility,
  setPeopleFilter,
  setPeopleSort,
} from "../../../../redux/leads/getLeadList";
import { getLeadsListAPI } from "../../../../redux/leads/getLeadsListAPI";
import { AppContext } from "../../../../components/callPanel/callContext";
import { useDispatch, useSelector } from "react-redux";
import { handleMaximizeTab } from "../../../../redux/widgets";
import ResponseAlert from "../../../../components/responseAlert";

const LeadsTable = ({
  classes,
  data,
  filters,
  sorting,
  stagesList,
  stagesListLoading,
  loading,
  columnsOrder,
  columnsVisibility,
  usersList,
  usersListLoading,
  usersObj,
  totalPeopleCount,
  tagsList,
  tempFilters,
  setTempFilters,
  tempSorting,
  setTempSorting,
}) => {
  const pageRef = React.useRef(1);
  const loadingRef = React.useRef(false);
  const requestRef = React.useRef(null);
  const debounceTimerRef = React.useRef(null);
  const gridStateRef = React.useRef({ filters: [], sorting: {} });
  const preFetchingRef = React.useRef(false);
  const lastScrollPositionRef = React.useRef(0);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { dialNumber } = useContext(AppContext);
  const location = useLocation();
  const gridRef = useGridApiRef();

  const handleOpenWidget = (type, phone, email) => {
    if (!widgets[type]) {
      if (rightPositions.length) {
        if (
          rightPositions[rightPositions.length - 1].right + 1450 >
          window.innerWidth
        ) {
          handleAlert();
        } else {
          if (type === "sendTextOpen") {
            dispatch(
              handleMaximizeTab({
                type: type,
                action: true,
                tabDetail: {
                  toNumber: [phone],
                },
              })
            );
          } else {
            dispatch(
              handleMaximizeTab({
                type: type,
                action: true,
                tabDetail: {
                  toEmailList: [{ email: email, name: "" }],
                },
              })
            );
          }
        }
      } else {
        if (type === "sendTextOpen") {
          dispatch(
            handleMaximizeTab({
              type: type,
              action: true,
              tabDetail: {
                toNumber: [phone],
              },
            })
          );
        } else {
          dispatch(
            handleMaximizeTab({
              type: type,
              action: true,
              tabDetail: {
                toEmailList: [{ email: email, name: "" }],
              },
            })
          );
        }
      }
    } else {
      setErrorAlert({
        errorMsg: "You can't open this widget because already opened",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const [columns, setColumns] = React.useState(
    getColumns(
      columnsOrder,
      stagesList,
      usersList,
      tagsList,
      dialNumber,
      handleOpenWidget
    )
  );
  const rightPositions = useSelector((state) => state.widgets.rightPositions);
  const widgets = useSelector((state) => state.widgets);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  useEffect(() => {
    if (!stagesListLoading) {
      setColumns(
        getColumns(
          columnsOrder,
          stagesList,
          usersList,
          usersObj,
          dialNumber,
          handleOpenWidget
        )
      );
    }
  }, [columnsOrder, stagesListLoading]);

  //----- save state of drag and drop of columns
  useEffect(() => {
    gridRef.current.subscribeEvent("columnHeaderDragEnd", (params) => {
      let newCols = gridRef.current.getAllColumns();
      newCols.shift();
      newCols = newCols.map((c) => c.field);
      dispatch(setPeopleColumnsOrder(newCols));
    });
  }, []);
  const setFilters = (newFilters) => {
    dispatch(setPeopleFilter(newFilters));
  };
  const setSorting = (sortFilter) => {
    dispatch(setPeopleSort(sortFilter));
  };

  // Modified loadMoreData function
  const loadMoreData = useCallback(
    (nextPage, isPrefetch = false) => {
      if (loading || loadingRef.current) {
        console.log("Loading blocked - already in progress");
        return;
      }

      console.log(
        "Starting load for page:",
        nextPage,
        "Current data:",
        data.length
      );
      loadingRef.current = true;

      dispatch(
        getLeadsListAPI({
          ...sorting,
          pageNumber: nextPage,
          pageSize: 25,
          filters: JSON.stringify(filters),
          from_agentprofit: location?.state?.from_agentprofit,
          agentprofit_agent_id: location?.state?.agentprofit_agent_id,
          agentprofit_start_date: location?.state?.agentprofit_start_date,
          agentprofit_end_date: location?.state?.agentprofit_end_date,
          agentprofit_team_id: location?.state?.agentprofit_team_id,
          resetList: false,
          offset: (nextPage - 1) * 25, // Add offset parameter
        })
      ).finally(() => {
        loadingRef.current = false;
        console.log(
          "Load complete for page:",
          nextPage,
          "New data length:",
          data.length
        );
      });
    },
    [sorting, filters, location?.state, dispatch, loading, data.length]
  );

  // Enhanced scroll handler with better detection
  const handleRowsScrollEnd = useCallback(
    (params) => {
      console.log("Scroll event:", {
        visibleRowsCount: params?.visibleRowsCount,
        totalPeopleCount,
        currentDataLength: data.length,
        loading,
        loadingRef: loadingRef.current,
      });

      // Don't load if we're already loading or have all the data
      if (loading || loadingRef.current) return;
      if (data.length >= totalPeopleCount) return;

      // Calculate next page based on current data length
      const nextPage = Math.floor(data.length / 25) + 1;

      console.log(
        "Loading next page:",
        nextPage,
        "Current data length:",
        data.length
      );

      loadMoreData(nextPage, false);
    },
    [totalPeopleCount, loading, data.length, loadMoreData]
  );

  // Optimized sort handler
  const handleSortModelChange = useCallback((model) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    const newSorting = model[0]
      ? { sortBy: model[0].field, sortOrder: model[0].sort }
      : {};
    if (
      JSON.stringify(newSorting) ===
      JSON.stringify(gridStateRef.current.sorting)
    )
      return;

    debounceTimerRef.current = setTimeout(() => {
      gridStateRef.current.sorting = newSorting;
      setTempSorting(newSorting);
    }, 300);
  }, []);

  // Optimized filter handler
  const handleFilterModelChange = useCallback((model) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    if (
      JSON.stringify(model.items) ===
      JSON.stringify(gridStateRef.current.filters)
    )
      return;

    debounceTimerRef.current = setTimeout(() => {
      gridStateRef.current.filters = model.items;
      setTempFilters(model.items);
    }, 300);
  }, []);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      if (requestRef.current) {
        requestRef.current.abort();
      }
      preFetchingRef.current = false;
      loadingRef.current = false;
      lastScrollPositionRef.current = 0;
    };
  }, []);

  // Reset scroll state when filters/sorting change
  useEffect(() => {
    pageRef.current = 1;
    loadingRef.current = false;
    preFetchingRef.current = false;
    lastScrollPositionRef.current = 0;
    if (requestRef.current) {
      requestRef.current.abort();
    }
  }, [filters, sorting]);

  // Detail view trigger for agent
  const onRowClick = async ({ id }) => {
    navigate(`/leads/people/details/${id}`);
  };
  const handleAlert = () => {
    setErrorAlert({
      errorMsg:
        "You can't open this widget because browser width is not enough for this!",
      errorType: "warning",
      isOpen: true,
    });
  };

  const applyFilters = useCallback(
    (newFilters) => {
      if (loadingRef.current) return;

      let fl = newFilters ? newFilters : tempFilters;
      let appliedFilters = fl.reduce((prev, curr) => {
        if (
          curr.columnField &&
          curr.operatorValue &&
          (curr.value ||
            ["isNotEmpty", "isEmpty", "isBad", "isGood"].includes(
              curr.operatorValue
            ))
        ) {
          prev.push(curr);
        }
        return prev;
      }, []);

      const shouldUpdate =
        JSON.stringify(appliedFilters) !== JSON.stringify(filters) ||
        JSON.stringify(sorting) !== JSON.stringify(tempSorting);

      if (shouldUpdate) {
        loadingRef.current = true;
        pageRef.current = 1;

        dispatch(
          getLeadsListAPI({
            filters: JSON.stringify(appliedFilters),
            ...tempSorting,
            pageSize: 25,
            pageNumber: 1,
            resetList: true,
            from_agentprofit: location?.state?.from_agentprofit,
            agentprofit_agent_id: location?.state?.agentprofit_agent_id,
            agentprofit_start_date: location?.state?.agentprofit_start_date,
            agentprofit_end_date: location?.state?.agentprofit_end_date,
            agentprofit_team_id: location?.state?.agentprofit_team_id,
          })
        ).finally(() => {
          loadingRef.current = false;
          setFilters(appliedFilters);
          setSorting(tempSorting);
        });
      }
    },
    [
      tempFilters,
      filters,
      sorting,
      tempSorting,
      dispatch,
      location?.state,
      setFilters,
      setSorting,
    ]
  );

  return (
    <>
      <Box
        sx={{
          margin: "10px 5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" component="p">
          Showing {totalPeopleCount} People
        </Typography>
        {/* {setSearchText ? (
          <OutlinedInput
            value={searchText === false ? "" : searchText}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            size="small"
            placeholder="Search..."
          />
        ) : null} */}
      </Box>
      <Grid container spacing={1}>
        <Grid item lg={filters?.length === 0 ? 12 : 9} md={12}>
          <Box className={classes.tableRoot}>
            <CustomFilterableDataGrid
              columns={columns}
              rows={data}
              columnVisibilityModel={columnsVisibility}
              apiRef={gridRef}
              sortModel={
                sorting.sortBy
                  ? [{ field: sorting.sortBy, sort: sorting.sortOrder }]
                  : []
              }
              filters={tempFilters}
              rowHeight={56}
              checkboxSelection
              loading={loading}
              filterMode="server"
              sortingMode="server"
              filterPanel={LeadsCustomFilters}
              toolbarPanel={LeadsCustomToolbar}
              onRowsScrollEnd={handleRowsScrollEnd}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
              onColumnVisibilityModelChange={(visibility) => {
                if (debounceTimerRef.current) {
                  clearTimeout(debounceTimerRef.current);
                }
                debounceTimerRef.current = setTimeout(() => {
                  dispatch(setPeopleColumnsVisibility(visibility));
                }, 300);
              }}
              onRowClick={(param) => onRowClick(param)}
              onPreferencePanelClose={(opened) => {
                if (opened.openedPanelValue === "filters") {
                  applyFilters();
                }
              }}
              disableVirtualization={false}
              rowBuffer={10}
              rowThreshold={10}
              columnBuffer={5}
              autoHeight={false}
              showCellVerticalBorder
              showColumnVerticalBorder
              disableColumnMenu={false}
              sx={{
                "& .MuiDataGrid-root": {
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                },
                "& .MuiDataGrid-main": {
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 auto",
                },
                "& .MuiDataGrid-virtualScroller": {
                  overflow: "auto !important",
                  marginBottom: "56px",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                    height: "8px",
                    display: "block",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#F5F5F5",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                },
                "& .MuiDataGrid-footerContainer": {
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "56px",
                  backgroundColor: "white",
                  borderTop: "1px solid rgba(224, 224, 224, 1)",
                  paddingRight: "8px",
                },
                "& .MuiDataGrid-virtualScrollerContent": {
                  minHeight: "auto",
                },
                "& .MuiDataGrid-virtualScrollerRenderZone": {
                  width: "fit-content",
                },
              }}
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
              headerHeight={56}
              disableExtendRowFullWidth={false}
              hideFooter={false}
              hideFooterPagination={true}
              hideFooterSelectedRowCount={false}
              components={{
                Footer: () => (
                  <div
                    style={{
                      height: 56,
                      display: "flex",
                      alignItems: "center",
                      padding: "0 16px",
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                      backgroundColor: "white",
                    }}
                  >
                    {/* Your footer content */}
                  </div>
                ),
              }}
            />
          </Box>
        </Grid>
        {filters?.length > 0 ? (
          <Grid item lg={3} md={12}>
            <SidebarAllFilter
              filters={tempFilters}
              columns={columns}
              apiRef={gridRef}
              apiCallApplyFilter={(filter) => {
                setTempFilters(filter);
                applyFilters(filter);
              }}
            />
          </Grid>
        ) : null}
        <ResponseAlert
          open={errorAlert.isOpen}
          setOpen={() =>
            setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
          }
          alertType={errorAlert.errorType}
          alertMessage={errorAlert.errorMsg}
        />
      </Grid>
    </>
  );
};

export default withStyles(styleSheet, { name: "LeadsTableStyle" })(LeadsTable);
