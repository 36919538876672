import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Grid, Container, Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDocDetails } from "../../../redux/docs";
import HeaderDocs from "./headerDocs";
import DataGridTable from "../../../components/dataGridTable";
import { Folder, Done } from "@mui/icons-material";
import Avatar from "../../../components/uiElements/avatar/index";
import moment from "moment";
import BuyerSeller from "./buyerSeller";
// import AddFolderModal from "./addFolderModal";
import AddFormModal from "./addFormModal";
import PrintModal from "./toolsModel/print";
import DownloadModal from "./toolsModel/download";
import ResponseAlert from "../../../components/responseAlert";
import { deleteForm } from "../../../redux/docs/addfolderapi";
import { useNavigate, useParams } from "react-router-dom";
import DocsDetailSkelton from "../skeltons/docsDetail";

const DocDetails = ({ classes }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  let navigate = useNavigate();
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [openPrint, setOpenPrint] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [openerror, setOpenerror] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMesage] = useState();
  const [fromData, SetFormData] = useState([]);
  const [search, setSearch] = useState("");
  const [openFormModal, setOpenFormModal] = useState(false);

  const docDetail = useSelector((item) => item.Docs.docDetail);

  const usersList = useSelector((state) => state.users.storedListOfUsers.list);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: ({ row }) => {
        return (
          <>
            {row.forms_data?.name ? (
              <Grid
                container
                onClick={() =>
                  redirectDetails(row.id, row.forms_data?.name, row)
                }
                className={classes.mAlign}
              >
                <Folder sx={{ marginRight: "10px" }} />
                {row.forms_data?.name?.slice(0, 65)}{" "}
                {row.forms_data?.name?.length > 65 ? "..." : ""}
              </Grid>
            ) : (
              ""
            )}
          </>
        );
      },
      minWidth: 400,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid
              container
              className={classes.status}
              onClick={() =>
                redirectDetails(
                  params.row.id,
                  params.row?.forms_data?.name,
                  params?.row
                )
              }
            >
              <Done />
              {params?.value}
            </Grid>
          </>
        );
      },
      sortComparator: (v1, v2) => v1.status.localeCompare(v2.status),
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "last_updated_by",
      headerName: "Last Modified By",
      renderCell: ({ row }) => {
        let updatedBy = usersList?.find(
          (item) => item.id === row?.last_updated_by
        );
        // console.log(params); last_updated_by
        return (
          <>
            {updatedBy?.full_name ? (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                onClick={() =>
                  redirectDetails(row.id, row.forms_data?.name, row)
                }
              >
                <Avatar
                  sx={{
                    width: 30,
                    height: 28,
                    marginRight: "10px !important",
                    borderRadius: "6px",
                    textTransform: "uppercase",
                    color: "white",
                    fontWeight: "400",
                  }}
                  variant="square"
                  s
                  src={updatedBy?.profile_images?.profile_img}
                >
                  {updatedBy?.full_name?.slice(0, 1)}
                </Avatar>
                {updatedBy?.full_name}
              </Stack>
            ) : (
              ""
            )}
          </>
        );
      },
      width: 300,
      editable: false,
      sortable: false,
    },
    {
      field: "last_updated_at",
      headerName: "Date Modified",
      renderCell: ({ row }) => {
        // console.log(params);
        return (
          <>
            <Grid
              container
              onClick={() => redirectDetails(row.id, row.forms_data?.name, row)}
            >
              {moment(row?.last_updated_at).format("lll")}
            </Grid>
          </>
        );
      },
      width: 300,
      editable: false,
      sortable: false,
    },
  ];

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getDocDetailsData(id);
  }, []);

  const getDocDetailsData = (docId) => {
    dispatch(getDocDetails({ id: docId }));
  };

  const redirectDetails = (formId, formName, fromDataOld) => {
    navigate(`form/edit`, {
      state: {
        formId,
        fromDataOld,
        formName,
      },
    });
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
  };

  const archiveFunction = () => {
    if (selectedCheckboxIds?.length > 0) {
      Promise.all(
        selectedCheckboxIds?.length &&
          selectedCheckboxIds.map(async (forlderId) => {
            await dispatch(deleteForm(forlderId));
          })
      ).then(() => {
        let new_form_data = [...fromData];
        let filteredFormData = new_form_data.filter(
          (item) =>
            selectedCheckboxIds.findIndex((ite) => ite === item.id) === -1
        );
        SetFormData(filteredFormData);
        setSelectedCheckboxIds([]);
        setOpenerror(true);
        setAlertType("success");
        setAlertMesage("Successfully deleted!");
        // getDocDetailsData(id);
      });
    } else {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please select folders or folder");
    }
  };

  const addFormModalOpen = () => {
    setOpenFormModal(true);
  };
  const handleClosePrint = () => setOpenPrint(false);
  const handleCloseDownload = () => setOpenDownload(false);
  return (
    <Box className={classes.docDetailPage}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box sx={{ height: 400, width: "100%" }}>
            {docDetail.loading ? (
              <Container maxWidth="xl">
                <br />
                <br />
                <DocsDetailSkelton />
              </Container>
            ) : (
              <>
                <HeaderDocs
                  archiveFunction={archiveFunction}
                  handleSearchInputChange={handleSearchInputChange}
                  docName={docDetail?.data?.name}
                  addform={addFormModalOpen}
                  printTrigger={setOpenPrint}
                  DownloadTrigger={setOpenDownload}
                />

                <Container maxWidth="xl">
                  <Grid
                    container
                    className={classes.tableAlignment}
                    spacing={2}
                  >
                    {docDetail?.data?.buyers?.length
                      ? docDetail?.data?.buyers?.map((buyer, index) => (
                          <Grid
                            key={index}
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={4}
                            md={4}
                          >
                            <BuyerSeller
                              title="Buyer"
                              tagName={`S${index + 1}`}
                              bsName={
                                (buyer?.first_name || "") +
                                " " +
                                (buyer?.first_name || "")
                              }
                              bsEmail={buyer?.email}
                            />
                          </Grid>
                        ))
                      : null}
                    {docDetail?.data?.sellers?.length
                      ? docDetail?.data?.sellers?.map((seller, index) => (
                          <Grid
                            key={index}
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={4}
                            md={4}
                          >
                            <BuyerSeller
                              title={"Seller"}
                              tagName={`S${index + 1}`}
                              bsName={
                                (seller?.first_name || "") +
                                " " +
                                (seller?.last_name || "")
                              }
                              bsEmail={seller?.email}
                            />
                          </Grid>
                        ))
                      : null}
                    {/* <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                            <BuyerSeller
                              tagName="CA"
                              bsName={`NA`}
                              bsEmail={`NA`}
                            />
                          </Grid> */}

                    {docDetail?.data?.buyer_brokers?.length
                      ? docDetail?.data?.buyer_brokers?.map(
                          (buyerBroker, index) => (
                            <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                              <BuyerSeller
                                title={"Buyer Broker"}
                                tagName={`BB${index + 1}`}
                                bsName={
                                  (buyerBroker?.first_name || "") +
                                  " " +
                                  (buyerBroker?.last_name || "")
                                }
                                bsEmail={buyerBroker?.email}
                              />
                            </Grid>
                          )
                        )
                      : null}

                    {docDetail?.data?.seller_brokers?.length
                      ? docDetail?.data?.seller_brokers?.map(
                          (sellerBroker, index) => (
                            <Grid
                              key={index}
                              item
                              xl={4}
                              lg={4}
                              sm={4}
                              xs={4}
                              md={4}
                            >
                              <BuyerSeller
                                tagName={`SB${index + 1}`}
                                title={"Seller Broker"}
                                bsName={
                                  (sellerBroker?.first_name || "") +
                                  " " +
                                  (sellerBroker?.last_name || "")
                                }
                                bsEmail={sellerBroker?.email}
                              />
                            </Grid>
                          )
                        )
                      : null}

                    {/* <Grid item xl={4} lg={4} sm={4} xs={4} md={4}>
                            <BuyerSeller tagName="L" bsName={``} bsEmail={``} />
                          </Grid> */}
                  </Grid>
                  <Grid container className={classes.tableAlignment}>
                    <DataGridTable
                      rows={
                        docDetail?.data?.forms?.length && search
                          ? [...docDetail?.data?.forms]?.filter((item) =>
                              item?.forms_data?.name
                                ?.toLowerCase()
                                ?.startsWith(search?.toLocaleLowerCase())
                            )
                          : docDetail?.data?.forms?.length
                          ? [...docDetail?.data?.forms]
                          : []
                      }
                      columns={columns}
                      pageSize={pageSize}
                      checkboxSelection={true}
                      rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                      setSelectedCheckboxIds={setSelectedCheckboxIds}
                      handlePageSizeChange={handlePageSizeChange}
                    />
                  </Grid>
                </Container>
                <ResponseAlert
                  setOpen={setOpenerror}
                  open={openerror}
                  alertType={alertType}
                  alertMessage={alertMessage}
                />
                <AddFormModal
                  open={openFormModal}
                  preForms={docDetail?.data?.forms}
                  setOpen={setOpenFormModal}
                  id={id}
                />
              </>
            )}

            <PrintModal
              open={openPrint}
              handleClose={handleClosePrint}
              modalWidht="auto"
              formData={fromData}
              documentID={id}
            />
            <DownloadModal
              open={openDownload}
              handleClose={handleCloseDownload}
              modalWidht="auto"
              formData={fromData}
              documentID={id}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styleSheet, { name: "DocDetailsStyle" })(DocDetails);
