import React, { useState, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../../../components/responseAlert";
import {
  createInvoice,
  editInvoice,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import moment from "moment";

const chargesCategories = [
  {
    title: "MLS Fees",
    value: "MLS Fees",
    categories: ["Listings & Promo Cost"],
  },
  {
    title: "Postage (Standard)",
    value: "Postage (Standard)",
    amount: 0.73,
    categories: ["Marketing Service"],
  },
  {
    title: "Postage (Postcard)",
    value: "Postage (Postcard)",
    amount: 0.56,
    categories: ["Marketing Service"],
  },
  {
    title: "Printing",
    value: "Printing",
    categories: ["Marketing Service", "Listings & Promo Cost"],
  },
  {
    title: "Annual RE Dues",
    value: "Annual RE Dues",
    categories: ["RE Fees & Dues"],
  },
  {
    title: "Shipping Fee",
    value: "Shipping Fee",
    categories: ["Marketing Service", "Logistics & Shipping"],
  },
  {
    title: "Social Media Ad",
    value: "Social Media Ad",
    categories: ["Marketing Service"],
  },
  {
    title: "Floorplan",
    value: "Floorplan",
    categories: ["Listings & Promo Cost"],
  },
  {
    title: "Misc",
    value: "Misc",
    categories: [
      "Marketing Service",
      "RE Fees & Dues",
      "Admin Service",
      "Listings & Promo Cost",
      "Logistics & Shipping",
      "Miscellaneous",
    ],
  },
  {
    title: "Photography",
    value: "Photography",
    categories: ["Listings & Promo Cost"],
  },
  {
    title: "Onboarding Fee",
    value: "Onboarding Fee",
    amount: 100.0,
    categories: ["Admin Service"],
  },
];
function CreateInvoice(props) {
  const { classes, open, setOpen, id, isUpdate, invoiceData } = props;
  const [invoiceDetail, setInvoiceDetail] = useState({
    invoice_date: "",
    invoice_number: "",
    category: "",
    description: "",
    email_alert: false,
  });
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [charges, setCharges] = useState([
    {
      category: "",
      quantity: "1",
      amount: "",
      description: "",
      is_new: true,
    },
  ]);

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const addInvoice = useSelector(
    (state) => state.agentRoster.AgentTabs.addInvoice
  );
  const updateInvoice = useSelector(
    (state) => state.agentRoster.AgentTabs.updateInvoice
  );

  const handleClose = () => {
    setOpen(false);
    setInvoiceDetail({
      invoice_date: "",
      invoice_number: "",
      category: "",
      description: "",
      email_alert: false,
    });
    setLoading(false);
    setCharges([
      {
        category: "",
        quantity: "1",
        amount: "",
        description: "",
        is_new: true,
      },
    ]);
  };
  const handleUpdateDetail = (value, field) => {
    setInvoiceDetail((preDetail) => ({
      ...preDetail,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors({ ...errors, [field]: "" });
    }
  };

  const handleAddCharge = () => {
    setCharges((preCharges) => [
      ...preCharges,
      {
        category: "",
        quantity: "1",
        amount: "",
        description: "",
        is_new: true,
      },
    ]);
  };

  const handleRemoveCharge = (index) => {
    let newCharges = [...charges];
    newCharges.splice(index, 1);
    setCharges(newCharges);
  };

  const handleUpdateCharges = (value, field, index) => {
    let preCharges = charges.map((charge) => ({ ...charge }));
    preCharges[index][field] = value;
    if (field === "category") {
      let categoryDetail = chargesCategories.find(
        (item) => item.value === value
      );
      if (categoryDetail.amount) {
        preCharges[index]["amount"] = (
          Number(categoryDetail.amount) * Number(preCharges[index]?.quantity)
        ).toFixed(2);
      } else {
        preCharges[index]["amount"] = "";
      }
    } else if (field === "quantity" && preCharges[index]?.category) {
      let categoryDetail = chargesCategories.find(
        (item) => item.value === preCharges[index]?.category
      );

      preCharges[index]["amount"] = (
        Number(value) * Number(categoryDetail?.amount)
      ).toFixed(2);
    }
    setErrors({ ...errors, charges: "" });
    setCharges(preCharges);
  };

  const validateFields = (fields) => {
    const errors = {};
    let isFormValid = true;

    fields.forEach(({ key, message }) => {
      if (!invoiceDetail?.[key]) {
        errors[key] = message;
        isFormValid = false;
      }
    });
    return { errors, isFormValid };
  };

  const handleValidate = () => {
    const validationRules = [
      { key: "invoice_date", message: "Please enter the invoice date" },
      { key: "invoice_number", message: "Please enter the invoice number" },
      { key: "category", message: "Please select the category" },
      { key: "description", message: "Please enter the invoice description" },
    ];

    const { errors, isFormValid } = validateFields(validationRules);
    setErrors(errors);
    return isFormValid;
  };

  const handleValidateDraft = () => {
    const validationRules = [
      { key: "invoice_date", message: "Please enter the invoice date" },
      { key: "invoice_number", message: "Please enter the invoice number" },
      // { key: "category", message: "Please select the category" },
      // { key: "description", message: "Please enter the invoice description" },
    ];

    const { errors, isFormValid } = validateFields(validationRules);
    setErrors(errors);
    return isFormValid;
  };

  const handleValidateCharges = () => {
    let isValid = true;
    let errors = {};
    if (charges?.length === 0) {
      isValid = false;
      errors["charges"] = "Please add the charges first!";
    } else if (
      charges?.filter(
        (item) => !item.category || !item.quantity || !item.amount
      )?.length
    ) {
      isValid = false;
      errors["charges"] =
        "Please provide details for the added charges or remove the charge. Category, amount, and quantity are required.";
    }
    setErrors((errs) => ({ ...errs, ...errors }));
    return isValid;
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully created the invoice",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleSuccessUpdate = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the invoice",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleSuccessDraft = () => {
    setLoading(false);
    setErrorAlert({
      errorMsg: "You have successfully saved the invoice  as draft",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };

  const handleError = (error) => {
    setLoading(false);
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleCreateInvoice = () => {
    if (handleValidate() && handleValidateCharges()) {
      dispatch(
        createInvoice({
          schema: {
            ...invoiceDetail,
            invoice_date: moment(invoiceDetail?.invoice_date).format(),
            charges: charges,
            user_id: id,
          },
          handleSuccess,
          handleError,
        })
      );
    }
  };

  const removeEmptyKeys = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, value]) =>
          value !== null &&
          value !== undefined &&
          value !== "" &&
          !(Array.isArray(value) && value.length === 0) &&
          !(typeof value === "object" && Object.keys(value).length === 0)
      )
    );
  };

  const handleSaveAsDraft = () => {
    if (handleValidateDraft()) {
      setLoading(true);
      dispatch(
        createInvoice({
          schema: removeEmptyKeys({
            ...invoiceDetail,
            charges: charges,
            user_id: id,
            is_draft: true,
          }),
          handleSuccess: handleSuccessDraft,
          handleError,
        })
      );
    }
  };
  const handleUpdateInvoice = () => {
    if (handleValidate() && handleValidateCharges()) {
      dispatch(
        editInvoice({
          invoice_id: invoiceData?.id,
          schema: {
            ...invoiceDetail,
            invoice_date: moment(invoiceDetail?.invoice_date).format(),
            charges: charges,
            user_id: id,
            status: invoiceData?.status === "draft" ? "Outstanding" : undefined,
          },
          handleSuccess: handleSuccessUpdate,
          handleError,
        })
      );
    }
  };

  useEffect(() => {
    if ((isUpdate, invoiceData?.id, open)) {
      setInvoiceDetail({
        invoice_date: moment(invoiceData?.invoice_date).format("YYYY-MM-DD"),
        invoice_number: invoiceData?.invoice_number,
        category: invoiceData?.category,
        description: invoiceData?.description,
        email_alert: invoiceData?.email_alert || false,
      });
      if (invoiceData?.charges?.length) {
        setCharges(
          [...invoiceData.charges]?.map((item) => ({
            category: item.category,
            quantity: item.quantity,
            amount: item.amount,
            description: item.description,
            is_new: false,
            id: item.id,
          }))
        );
      }
    }
  }, [isUpdate, invoiceData?.id, open]);

  return (
    <>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            {isUpdate ? "Update" : "Create"} Invoice
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.invoiceForm}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Date of Invoice
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                type="date"
                value={invoiceDetail.invoice_date}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "invoice_date")
                }
                error={errors.invoice_date}
                helperText={errors.invoice_date}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>Invoice #</InputLabel>
              <TextField
                fullWidth
                size="small"
                value={invoiceDetail.invoice_number}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "invoice_number")
                }
                error={errors.invoice_number}
                helperText={errors.invoice_number}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Invoice Category
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={invoiceDetail.category}
                onChange={(e) => handleUpdateDetail(e.target.value, "category")}
                error={errors.category}
                helperText={errors.category}
                select
              >
                <MenuItem value="Marketing Service">Marketing Service</MenuItem>
                <MenuItem value="RE Fees & Dues">RE Fees & Dues</MenuItem>
                <MenuItem value="Admin Service">Admin Service</MenuItem>
                <MenuItem value="Listings & Promo Cost">
                  Listings & Promo Cost
                </MenuItem>
                <MenuItem value="Logistics & Shipping">
                  Logistics & Shipping
                </MenuItem>
                <MenuItem value="Miscellaneous">Miscellaneous </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.InputLabel}>
                Invoice Description
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={invoiceDetail.description}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "description")
                }
                inputProps={{
                  maxLength: 50, // Set the maximum character limit
                }}
                error={errors.description}
                helperText={errors.description}
              />
            </Grid>
          </Grid>
          <Box className={classes.chargesSection}>
            <InputLabel className={classes.InputLabel2}>Add Charges</InputLabel>
            {errors["charges"] ? (
              <Typography sx={{ color: "red" }}>{errors["charges"]}</Typography>
            ) : null}

            {charges?.map((item, index) => (
              <Box
                key={`charge-${index}`}
                className={classes.chargesSectionItem}
              >
                <Box className={classes.chargesSectionItemHeading}>
                  {" "}
                  <InputLabel
                    className={classes.InputLabel}
                    sx={{ mb: "0px !important" }}
                  >
                    Charge {index + 1}
                  </InputLabel>
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveCharge(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Grid container spacing={2} sx={{ padding: "20px" }}>
                  <Grid item xs={6}>
                    <InputLabel className={classes.InputLabel}>
                      Category
                    </InputLabel>
                    <TextField
                      fullWidth
                      value={item?.category}
                      size="small"
                      select
                      disabled={!invoiceDetail?.category}
                      onChange={(e) =>
                        handleUpdateCharges(e.target.value, "category", index)
                      }
                    >
                      {chargesCategories
                        ?.filter((item) =>
                          item.categories?.includes(invoiceDetail?.category)
                        )
                        ?.map((categoryItem, index) => (
                          <MenuItem value={categoryItem.value}>
                            {categoryItem.title}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <InputLabel className={classes.InputLabel}>
                      Quantity
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      value={item?.quantity}
                      onChange={(e) =>
                        handleUpdateCharges(e.target.value, "quantity", index)
                      }
                      inputProps={{ min: 1 }}
                      type={"number"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className={classes.InputLabel}>
                      Amount
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      value={item?.amount}
                      onChange={(e) =>
                        handleUpdateCharges(e.target.value, "amount", index)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel className={classes.InputLabel}>
                      Description
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      value={item?.description}
                      inputProps={{
                        maxLength: 50, // Set the maximum character limit
                      }}
                      onChange={(e) =>
                        handleUpdateCharges(
                          e.target.value,
                          "description",
                          index
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button
              variant="outlined"
              color="inherit"
              className={classes.saveButton}
              sx={{ mt: "12px" }}
              onClick={handleAddCharge}
            >
              Add Charge
            </Button>
          </Box>
          <Box sx={{ mt: "24px" }}>
            <InputLabel className={classes.InputLabel}>
              Schedule Email Alerts to Agent
            </InputLabel>
            <Box className={classes.selectionBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={invoiceDetail?.email_alert}
                    onChange={(e) =>
                      handleUpdateDetail(e.target.checked, "email_alert")
                    }
                  />
                }
                label="New Invoice"
                color="inherit"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {" "}
          {isUpdate ? (
            <Box className={classes.buttonSection}>
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                loadingPosition="start"
                loading={updateInvoice.isLoading}
                onClick={handleUpdateInvoice}
                disabled={updateInvoice.isLoading}
              >
                Update invoice
              </LoadingButton>
            </Box>
          ) : (
            <Box className={classes.buttonSection}>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.saveButton}
                loading={loading}
                onClick={handleSaveAsDraft}
                disabled={loading}
              >
                save as draft
              </Button>
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                loadingPosition="start"
                loading={addInvoice.isLoading && !loading ? true : false}
                onClick={handleCreateInvoice}
                disabled={addInvoice.isLoading}
              >
                Create invoice
              </LoadingButton>
            </Box>
          )}
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </>
  );
}
export default withStyles(styleSheet, { name: "CreateInvoiceStyle" })(
  CreateInvoice
);
