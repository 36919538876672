import React, { useState, useEffect } from "react";
import { Box, Paper, Button, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  getProcessedACH,
  processACH,
  updateACHStore,
} from "../../../redux/finance/getProcessedACH";
import moment from "moment";
import ResponseAlert from "../../../components/responseAlert";
import { getPendingPayments } from "../../../redux/finance/getPendingPayments";
import LoadingButton from "@mui/lab/LoadingButton";

const generateTime = () => {
  const timeEST = moment().tz("America/New_York");
  const futureTime = moment()
    .tz("America/New_York")
    .startOf("day")
    .add(17, "h");
  if (timeEST.hour() > 17 || timeEST.day() === 6 || timeEST.day() === 7) {
    return false;
  } else {
    return futureTime.diff(timeEST, "minutes");
  }
};

function AchToSend(props) {
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  let { classes } = props;
  const dispatch = useDispatch();
  const [selectionModel, setSelectionModel] = React.useState([]);
  const {
    loading,
    allACH: data,
    processLoading,
  } = useSelector((item) => item.finance.achData);
  useEffect(() => {
    dispatch(getProcessedACH()).then((res) => {
      setSelectionModel(res?.payload?.data.map((r, i) => r.id));
    });
  }, []);

  const columns = [
    {
      field: "payee",
      headerName: "Payee",
      renderCell: (params) => {
        return params.row.payee_name;
      },
      sortComparator: (v1, v2) => v1.AssetName.localeCompare(v2.AssetName),
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      renderCell: (params) => {
        return `$${parseFloat(params.row.amount).toFixed(2)}`;
      },
      sortComparator: (v1, v2) =>
        v1.SerialNumber.localeCompare(v2.SerialNumber),
      width: 150,
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "routing",
      headerName: "Routing/Account",
      renderCell: (params) => {
        return params.row.vendor?.bank_details?.routing_number || "NULL";
      },
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "closedProperty",
      headerName: "Closed Property",
      flex: 1,
      renderCell: (params) => {
        return params.row.property_address;
      },
      width: 170,
      editable: false,
      sortable: false,
    },
    {
      field: "renderCell",
      flex: 1.5,
      headerName: "Action",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            sx={{
              fontSize: "13px",
              backgroundColor: "black",
              "&:hover": { backgroundColor: "black" },
            }}
            variant="contained"
            className={!params.row.toBeHeld && "btn-payment-held"}
            onClick={(e) => {
              e.preventDefault();

              let model = [];
              if (selectionModel.includes(params.row.id)) {
                model = selectionModel.filter((f) => f !== params.row.id);
              } else {
                model = selectionModel.concat([params.row.id]);
              }
              setSelectionModel(model);
              dispatch(
                updateACHStore(
                  data.map((r, i) => ({
                    ...r,
                    needToProcess: model.includes(r.id),
                  }))
                )
              );
            }}
          >
            {params.row.needToProcess ? "Hold Payment" : "Payment Held"}
          </Button>
        );
      },
    },
  ];

  const processACHnadler = () => {
    const formData = {
      ids: data.filter((e) => selectionModel.includes(e.id)).map((s) => s.id),
    };
    dispatch(processACH(formData)).then((res) => {
      console.log(res);
      if (res.payload.status === 200) {
        dispatch(getProcessedACH()).then((res) => {
          setSelectionModel(res?.payload?.data.map((r, i) => r.id));
        });
        dispatch(getPendingPayments());
        setErrorAlert({
          errorMsg: `${selectionModel.length} ACH have successfully processed`,
          errorType: "success",
          isOpen: true,
        });
      } else {
        setErrorAlert({
          errorMsg: res.payload.message,
          errorType: "error",
          isOpen: true,
        });
      }
    });
  };
  const CustomFooter = (props) => {
    return (
      <Box className={classes.dataGridButtonsACH}>
        <Box>
          <Box className={classes.selectedChecksBox}>
            <Typography variant="p">
              {generateTime()
                ? `Submit within the next ${(
                    generateTime() / 60
                  ).toFixed()} hours ${
                    generateTime() % 60
                  } minute(s) to process today`
                : "Submission deadline has passed, submissions will be processed next business day"}
            </Typography>
          </Box>
          <LoadingButton
            variant="contained"
            sx={{
              backgroundColor: "black",
              "&:hover": { backgroundColor: "black" },
              minWidth: "120px",
            }}
            disabled={selectionModel.length === 0}
            onClick={(e) => {
              if (selectionModel.length && !processLoading) {
                processACHnadler();
              }
            }}
            loadingPosition="end"
            loading={processLoading}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      {" "}
      <br />
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={data}
          columns={columns}
          components={{
            LoadingOverlay: LinearProgress,
            Footer: CustomFooter,
          }}
          selectionModel={selectionModel}
          onRowSelectionModelChange={(itm) => {
            setSelectionModel(itm);
            dispatch(
              updateACHStore(
                data.map((r, i) => ({
                  ...r,
                  needToProcess: itm.includes(r.id),
                }))
              )
            );
          }}
          loading={loading}
          rowHeight={56}
          disableColumnResize
          disableColumnMenu
          disableChildrenSorting
          checkboxSelection
          disableSelectionOnClick
        />
      </Paper>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}
export default AchToSend;
