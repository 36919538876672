import React, { Fragment, useState, useEffect, useCallback } from "react";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  TextField,
  Grid,
  Autocomplete,
  Chip,
} from "@mui/material";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SearchProperties from "./searchProperties.jsx";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "../../../../components/uiElements/avatar";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddForms from "./addForms.jsx";
import {
  getSellersAndDeeds,
  getFolders,
  addFolder,
  getPackages,
} from "../../../../redux/docs";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import UpdateNameInfo from "./updateNameInfo.jsx";
import UsersSelection from "./usersSelection.jsx";

function AddFolder(props) {
  const { classes, open, setOpen } = props;
  const [step, setStep] = useState(1);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [transactionDetail, setTransactionDetail] = useState({
    transaction_type: "sale",
    representation_side: "buying",
    name: "",
    address: "",
    street: "",
    city: "",
    zip_code: "",
    state: "",
    tax_id: "",
    agent_id: "",
    mls_listing_id: "",
  });
  const [buyers, setBuyers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [listingAgents, setListingAgents] = useState([]);
  const [errors, setErrors] = useState({});
  const [forms, setForms] = useState([]);
  const [packages, setPackages] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateDetail, setUpdateDetail] = useState({});
  const dispatch = useDispatch();
  const adminDetail = useSelector((state) => state.adminAuth?.adminDetail);
  const sellersAnsDeeds = useSelector((state) => state.Docs?.sellersAnsDeeds);
  const folders = useSelector((state) => state.Docs?.folders);
  const packagesData = useSelector((state) => state.Docs?.packages);

  const createFolder = useSelector((state) => state.Docs?.createFolder);
  const agents = useSelector((state) =>
    state.users.storedListOfUsers.list.filter(
      (user) => user.is_agent === true && user.status === "active"
    )
  );
  const handleClose = () => {
    setOpen(false);
    setStep(1);
    resetStep2({ agent_id: "" });
    reset2Step2();
  };

  const resetStep2 = (data) => {
    setBuyers([]);
    setSellers([]);
    setListingAgents([]);
    setSelectedProperty(null);
    setTransactionDetail((preDetail) => ({
      ...preDetail,
      address: "",
      street: "",
      city: "",
      zip_code: "",
      state: "",
      tax_id: "",
      mls_listing_id: "",
      ...data,
    }));
  };

  const handleValidateStep2 = () => {
    let formValid = true;
    let errors = {};
    if (!transactionDetail.name) {
      formValid = false;
      errors["name"] = "Please enter the folder name";
    }
    if (adminDetail?.role?.name === "Admin" && !transactionDetail?.agent_id) {
      formValid = false;
      errors["agent_id"] = "Please select the agent";
    }
    if (!transactionDetail?.address) {
      formValid = false;
      errors["address"] = "Please select the property address";
    }
    if (buyers?.length === 0) {
      formValid = false;
      errors["buyers"] = "Please select the buyers";
    }
    if (sellers?.length === 0) {
      formValid = false;
      errors["sellers"] = "Please select the sellers";
    }
    // if (listingAgents?.length === 0) {
    //   formValid = false;
    //   errors["listingAgents"] = `Please select the ${
    //     transactionDetail?.representation_side === "buying"
    //       ? "listing agent"
    //       : "buyer agent"
    //   }`;
    // }

    setErrors({ ...errors });
    return formValid;
  };

  const reset2Step2 = () => {
    setBuyers([]);
    setSellers([]);
    setListingAgents([]);
    setTransactionDetail((preDetail) => ({
      ...preDetail,
      name: "",
      address: "",
      street: "",
      city: "",
      zip_code: "",
      state: "",
      tax_id: "",
      mls_listing_id: "",
    }));
  };

  const handleSuccess = () => {
    toast.success("You have successfully created the Folder", {
      theme: "colored",
    });
    handleClose();
  };

  const handleError = (error) => {
    toast.error(JSON.stringify(error));
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2 && handleValidateStep2()) {
      setStep(3);
    } else if (step === 3) {
      if (forms.length) {
        let object = {
          schema: {
            ...transactionDetail,
            sellers,
            buyers,
            seller_brokers:
              transactionDetail?.representation_side === "buying"
                ? listingAgents
                : undefined,
            buyer_brokers:
              transactionDetail?.representation_side === "listing"
                ? listingAgents
                : undefined,
            packages: packages?.length ? packages : undefined,
            forms,
          },
          handleSuccess,
          handleError,
        };
        dispatch(addFolder(object));
      } else {
        toast.error("Please select the form", { theme: "colored" });
      }
    }
  };

  const handleBackButton = () => {
    if (step > 1) {
      setStep((preStep) => preStep - 1);
    }
  };

  const handleUpdateDetail = (field, value) => {
    setTransactionDetail((preDetail) => ({ ...preDetail, [field]: value }));
    if (errors[field]) {
      setErrors((preErrors) => ({
        ...preErrors,
        [field]: null,
      }));
    }
  };

  const handleConfirm = (preDetail) => {
    if (preDetail.type === "buyers") {
      setBuyers(preDetail.data);
    } else if (preDetail.type === "sellers") {
      setSellers(preDetail.data);
    }
    if (preDetail.type === "listingAgents") {
      setListingAgents(preDetail.data);
    }
    setOpenUpdate(false);
  };

  const handleUpdateData = (data, StreetNumber, allData) => {
    setTransactionDetail((preDetail) => ({
      ...preDetail,
      ...data,
    }));

    dispatch(
      getSellersAndDeeds({
        street_name: data?.street,
        city: data?.city,
        state: data?.state,
        zip_code: data?.zip_code,
        street_number: StreetNumber,
      })
    );
    if (transactionDetail?.representation_side === "buying") {
      let newAgents = [];
      if (allData?.ListAgentFirstName || allData?.ListAgentFullName) {
        newAgents.push({
          first_name: allData?.ListAgentFirstName
            ? allData?.ListAgentFirstName
            : allData?.ListAgentFullName?.split(" ")[0],
          last_name: allData?.ListAgentLastName
            ? allData?.ListAgentLastName
            : allData?.ListAgentFullName?.split(" ")[1],
          email: allData?.ListAgentEmail,
          agent_mls_id: allData?.ListAgentMlsId,
          license_number: allData?.ListAgentLicenseNumber,
          office_name: allData?.ListOfficeName,
        });
      }
      if (allData?.CoListAgentFirstName) {
        newAgents.push({
          first_name: allData?.CoListAgentFirstName,
          last_name: allData?.CoListAgentLastName,
          email: allData?.CoListOfficeEmail,
          agent_mls_id: allData?.CoListOfficeMlsId,
          license_number: allData?.CoListAgentLicenseNumber,
          office_name: allData?.CoListOfficeName,
        });
      }
      setListingAgents(newAgents);
    }
    if (transactionDetail?.representation_side === "listing") {
      if (selectedProperty?.BuyerAgentFirstName) {
        setListingAgents([
          {
            first_name: selectedProperty?.BuyerAgentFirstName,
            last_name: selectedProperty?.BuyerAgentLastName,
            email: selectedProperty?.BuyerAgentEmail,
            agent_mls_id: selectedProperty?.BuyerAgentMlsId,
            license_number: selectedProperty?.BuyerAgentLicenseNumber,
            office_name: selectedProperty?.BuyerAgentOfficeName,
          },
        ]);
      }
    }
    setErrors({});
  };

  const handleGetSellerOptions = useCallback(() => {
    let newSellers = [];
    if (sellersAnsDeeds?.data?.owner) {
      if (sellersAnsDeeds?.data?.owner?.name) {
        sellersAnsDeeds?.data?.owner?.name?.split(",")?.map((name) => {
          newSellers.push({
            first_name: name?.split(" ")?.[0],
            last_name: name?.split(" ")?.[1],
            street: sellersAnsDeeds?.data?.owner?.formatted_street_address,
            city: sellersAnsDeeds?.data?.owner?.city,
            state: sellersAnsDeeds?.data?.owner?.state,
            zip_code: sellersAnsDeeds?.data?.owner?.zip_code,
          });
        });
      }
      if (sellersAnsDeeds?.data?.owner?.second_name) {
        sellersAnsDeeds?.data?.owner?.second_name?.split(",")?.map((name) => {
          newSellers.push({
            first_name: name?.split(" ")?.[0],
            last_name: name?.split(" ")?.[1],
            street: sellersAnsDeeds?.data?.owner?.formatted_street_address,
            city: sellersAnsDeeds?.data?.owner?.city,
            state: sellersAnsDeeds?.data?.owner?.state,
            zip_code: sellersAnsDeeds?.data?.owner?.zip_code,
          });
        });
      }
    } else {
    }
    return newSellers;
  }, [sellersAnsDeeds?.data?.owner?.name]);

  const handleGetListingAgentOptions = useCallback(() => {
    let newAgents = [];
    if (transactionDetail?.representation_side === "buying") {
      if (selectedProperty?.ListAgentFirstName) {
        newAgents.push({
          first_name: selectedProperty?.ListAgentFirstName,
          last_name: selectedProperty?.ListAgentLastName,
          email: selectedProperty?.ListAgentEmail,
          agent_mls_id: selectedProperty?.ListAgentMlsId,
          license_number: selectedProperty?.ListAgentLicenseNumber,
          office_name: selectedProperty?.ListOfficeName,
        });
      }
      if (selectedProperty?.CoListAgentFirstName) {
        newAgents.push({
          first_name: selectedProperty?.CoListAgentFirstName,
          last_name: selectedProperty?.CoListAgentLastName,
          email: selectedProperty?.CoListOfficeEmail,
          agent_mls_id: selectedProperty?.CoListOfficeMlsId,
          license_number: selectedProperty?.CoListAgentLicenseNumber,
          office_name: selectedProperty?.CoListOfficeName,
        });
      }
      if (transactionDetail?.representation_side === "listing") {
        if (selectedProperty?.BuyerAgentFirstName) {
          newAgents.push({
            first_name: selectedProperty?.BuyerAgentFirstName,
            last_name: selectedProperty?.BuyerAgentLastName,
            email: selectedProperty?.BuyerAgentEmail,
            agent_mls_id: selectedProperty?.BuyerAgentMlsId,
            license_number: selectedProperty?.BuyerAgentLicenseNumber,
            office_name: selectedProperty?.BuyerAgentOfficeName,
          });
        }
      }
    }

    return newAgents;
  }, [selectedProperty?.ListingId]);

  useEffect(() => {
    if (open && !folders?.isLoading && folders?.data?.length === 0) {
      dispatch(getFolders());
    }
  }, [open]);

  useEffect(() => {
    if (open && !packagesData?.isLoading && packagesData?.data?.length === 0) {
      dispatch(getPackages());
    }
  }, [open]);

  return (
    <Fragment>
      {" "}
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => {}}
        aria-labelledby="add-new-folder-model"
        aria-describedby="add-new-folder-model-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>New Folder</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={{ width: `${step * 33.3}%` }} className={classes.stepsSection}>
          <Box className={classes.stepsDisplay}>
            <Typography className={classes.stepsDisplayContent}>
              Step {step} of 3
            </Typography>
          </Box>
        </Box>
        <DialogContent>
          {step === 1 ? (
            <FormControl className={classes.folderForm}>
              <FormLabel className={classes.inputLabel} sx={{ mt: "20px" }}>
                Please select the transaction type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="transaction-type-label"
                name="transaction-type-group"
                value={transactionDetail?.transaction_type}
                onChange={(e) =>
                  handleUpdateDetail("transaction_type", e.target.value)
                }
              >
                <FormControlLabel
                  value="sale"
                  control={<Radio />}
                  label={
                    <Box className={classes.radioLabel}>
                      <AttachMoneyRoundedIcon fontSize="small" />
                      <Typography>Sale</Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="lease"
                  control={<Radio />}
                  sx={{ ml: "30px" }}
                  label={
                    <Box className={classes.radioLabel}>
                      <InsertDriveFileOutlinedIcon fontSize="small" />
                      <Typography>Lease</Typography>
                    </Box>
                  }
                />
              </RadioGroup>
              <FormLabel className={classes.inputLabel}>
                Please select which side(s) you will be representing
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="transaction-type-label"
                name="transaction-type-group"
                value={transactionDetail?.representation_side}
                onChange={(e) =>
                  handleUpdateDetail("representation_side", e.target.value)
                }
              >
                <FormControlLabel
                  value="buying"
                  control={<Radio />}
                  label={
                    <Box className={classes.radioLabel}>
                      <ShoppingBasketOutlinedIcon fontSize="small" />
                      <Typography>Buying Side</Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="listing"
                  control={<Radio />}
                  sx={{ ml: "30px" }}
                  label={
                    <Box className={classes.radioLabel}>
                      <ListAltOutlinedIcon fontSize="small" />
                      <Typography>Listing Side</Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  sx={{ ml: "30px" }}
                  label={
                    <Box className={classes.radioLabel}>
                      <MoreHorizOutlinedIcon fontSize="small" />
                      <Typography>Both Sides</Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : step === 2 ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  Folder Name
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Transaction Name"
                  value={transactionDetail?.name}
                  onChange={(e) => handleUpdateDetail("name", e.target.value)}
                  error={errors?.name}
                  helperText={errors?.name}
                />
              </Grid>

              {adminDetail?.role?.name === "Admin" ? (
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>
                    Select Agent
                  </InputLabel>{" "}
                  <Autocomplete
                    disablePortal
                    id="onboarding-coordinator-dropdown"
                    value={selectedAgent}
                    onChange={(event, value) => {
                      setSelectedAgent(value);
                      resetStep2({ agent_id: value?.id });
                      setErrors((preErrors) => ({
                        ...preErrors,
                        agent_id: "",
                      }));
                    }}
                    getOptionLabel={(option) => option.full_name}
                    options={agents?.length ? [...agents] : []}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Agents"
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={errors.agent_id}
                        helperText={errors.agent_id ? "* required field" : ""}
                        className={classes.field}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: selectedAgent?.id ? (
                            <Avatar
                              sx={{
                                width: "28px",
                                height: "28px",
                                fontSize: "12px",
                                ml: "10px",
                              }}
                              alt={selectedAgent?.full_name}
                              src={
                                selectedAgent?.profile_images
                                  ?.profile_img_thumbnail || null
                              }
                              className={classes.avatar}
                            >{`${selectedAgent?.first_name?.slice(
                              0,
                              1
                            )}${selectedAgent?.last_name?.slice(
                              0,
                              1
                            )}`}</Avatar>
                          ) : null,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Avatar
                          alt={option.full_name}
                          src={
                            option?.profile_images?.profile_img ||
                            option?.profile_images?.profile_img_thumbnail
                          }
                          sx={{
                            width: "28px",
                            height: "28px",
                            fontSize: "12px",
                            mr: "10px",
                          }}
                          className={classes.avatar}
                        >{`${option?.first_name?.slice(
                          0,
                          1
                        )}${option?.last_name?.slice(0, 1)}`}</Avatar>
                        {option.full_name}
                      </li>
                    )}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>Address</InputLabel>
                <SearchProperties
                  setFormData={() => {}}
                  marketMlsId={1}
                  agentData={
                    adminDetail?.role?.name === "Admin"
                      ? selectedAgent
                      : adminDetail
                  }
                  handleUpdateData={handleUpdateData}
                  {...props}
                  transactionDetail={transactionDetail}
                  reset2Step2={reset2Step2}
                  selectedProperty={selectedProperty}
                  setSelectedProperty={setSelectedProperty}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12}>
                <UsersSelection
                  type="buyers"
                  label="Buyer Name"
                  preOptions={[]}
                  handleSelect={(value) => {
                    setBuyers(value);
                    setErrors((preErrors) => ({ ...preErrors, buyers: "" }));
                  }}
                  value={buyers}
                  errors={errors}
                  disabled={false}
                  setOpenUpdate={setOpenUpdate}
                  setUpdateDetail={setUpdateDetail}
                  labelType="buyer"
                  {...props}
                />
              </Grid>
              <Grid item xs={12}>
                <UsersSelection
                  type="sellers"
                  label="Seller Name"
                  preOptions={handleGetSellerOptions()}
                  handleSelect={(value) => {
                    setSellers(value);
                    setErrors((preErrors) => ({ ...preErrors, sellers: "" }));
                  }}
                  value={sellers}
                  errors={errors}
                  disabled={!transactionDetail?.address}
                  setOpenUpdate={setOpenUpdate}
                  setUpdateDetail={setUpdateDetail}
                  labelType="seller"
                  {...props}
                />
              </Grid>

              {transactionDetail?.representation_side === "both" ? null : (
                <Grid item xs={12}>
                  <InputLabel className={classes.inputLabel}>
                    {transactionDetail?.representation_side === "buying"
                      ? " Listing Agent(s)"
                      : "Buyer's Agent"}
                  </InputLabel>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-sellers-tags"
                    options={handleGetListingAgentOptions()}
                    disabled={!transactionDetail?.address}
                    getOptionLabel={(option) =>
                      option?.first_name + " " + option?.last_name
                    }
                    value={listingAgents}
                    onChange={(event, value) => {
                      setListingAgents(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Start typing name here..."
                        size="small"
                        fullWidth
                        error={errors?.listingAgents}
                        helperText={errors?.listingAgents}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={option.first_name}
                          label={option?.first_name + " " + option?.last_name}
                          size="small" // ✅
                          deleteIcon={
                            <ClearRoundedIcon
                              fontSize="small"
                              sx={{ color: "black" }}
                            />
                          }
                          onClick={() => {
                            setOpenUpdate(true);
                            setUpdateDetail({
                              type: "listingAgents",
                              data: listingAgents,
                            });
                          }}
                          sx={{
                            borderRadius: "3px",
                          }}
                        />
                      ))
                    }
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <AddForms
              {...props}
              transactionDetail={transactionDetail}
              forms={forms}
              setForms={setForms}
              packages={packages}
              setPackages={setPackages}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "24px",
            paddingTop: "6px",
          }}
        >
          <Box
            sx={{
              display: step > 1 ? "flex" : "",
              justifyContent: "space-between",
              alignItems: "center",
              width: step > 1 ? "100%" : "auto",
            }}
          >
            {" "}
            {step > 1 ? (
              <Button
                className={classes.backButton}
                color="inherit"
                variant="outlined"
                onClick={handleBackButton}
              >
                Back
              </Button>
            ) : null}
            <LoadingButton
              onClick={handleNext}
              className={classes.nextStep}
              variant="contained"
              loadingPosition="start"
              loading={createFolder.isLoading}
            >
              {step === 1 ? "Next Step" : "Continue"}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
      <UpdateNameInfo
        {...props}
        open={openUpdate}
        setOpen={setOpenUpdate}
        updateDetail={updateDetail}
        handleConfirm={handleConfirm}
      />
    </Fragment>
  );
}

export default withStyles(styleSheet, { name: "AddFolderStyle" })(AddFolder);
