import React, { useState, useEffect, useRef } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Stack,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  getUnpaidInvoices,
  createPayment,
  deleteAttachment,
  updatePayment,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import moment from "moment";
import { NumberFormat } from "../../../../../utils/numberFormat";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ResponseAlert from "../../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmModal from "../../../../../components/globalModal/ConfirmModal";

function ApplyPayment(props) {
  const {
    classes,
    open,
    setOpen,
    id,
    isUpdate,
    prePaymentDetail,
    handleSuccessCall,
  } = props;
  const dispatch = useDispatch();
  const [paymentDetail, setPaymentDetail] = useState({
    payment_date: "",
    amount: "",
    type: "",
    description: "",
    email_alert: false,
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [fileDetail, setFileDetail] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [errors, setErrors] = useState({});
  const [filesPreview, setFilesPreview] = useState([]);
  const [files, setFiles] = useState([]);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const isFirstRender = useRef(false);
  const unPaidInvoices = useSelector(
    (state) => state.agentRoster.AgentTabs.unPaidInvoices
  );
  const applyPayment = useSelector(
    (state) => state.agentRoster.AgentTabs.applyPayment
  );
  const editPayment = useSelector(
    (state) => state.agentRoster.AgentTabs.editPayment
  );
  const delAttachment = useSelector(
    (state) => state.agentRoster.AgentTabs.delAttachment
  );

  const handleClose = () => {
    setOpen(false);
    setPaymentDetail({
      payment_date: "",
      amount: "",
      type: "",
      description: "",
      email_alert: false,
    });
    setSelectedInvoices([]);
    setFilesPreview([]);
    setFiles([]);
    isFirstRender.current = false;
  };
  const handleUpdateDetail = (value, field) => {
    setPaymentDetail((preDetail) => ({
      ...preDetail,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors({ ...errors, [field]: "" });
    }
  };

  const handleSelectionChange = (selectionModel) => {
    if (!isFirstRender.current) {
      isFirstRender.current = true;
      return; // Ignore first execution
    } else {
      const selected = unPaidInvoices?.data.filter((row) =>
        selectionModel.includes(row.id)
      );
      setSelectedInvoices(
        selected?.map((item) => ({
          ...item,
          amount_paid: "",
        }))
      );
    }
  };

  const handleFileChange = (files) => {
    let id = Math.random().toString(36).substring(7);
    setFiles((preFiles) => [...preFiles, { id: id, file: files[0] }]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setFilesPreview([
        ...filesPreview,
        {
          id: id,
          file: reader.result,
          url: URL.createObjectURL(files[0]),
        },
      ]);
    };
    let url = reader.readAsDataURL(files[0]);
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };
  const handleSuccessAttachment = () => {
    setErrorAlert({
      errorMsg: "You have successfully deleted the attachment",
      errorType: "success",
      isOpen: true,
    });
    setFileDetail({});
    setOpenDelete(false);
    setFilesPreview(filesPreview.filter((ite) => ite.id != fileDetail.id));
  };

  const handleDelFile = (item) => {
    if (item?.file_path) {
      setFileDetail(item);
      setOpenDelete(true);
    } else {
      setFiles(files.filter((ite) => ite.id != item.id));
      setFilesPreview(filesPreview.filter((ite) => ite.id != item.id));
    }
  };

  const handleConfirm = () => {
    dispatch(
      deleteAttachment({
        attachment_id: fileDetail.id,
        payment_id: prePaymentDetail.id,
        handleError,
        handleSuccess: handleSuccessAttachment,
      })
    );
  };

  const validateFields = (fields) => {
    const errors = {};
    let isFormValid = true;

    fields.forEach(({ key, message }) => {
      if (!paymentDetail?.[key]) {
        errors[key] = message;
        isFormValid = false;
      }
    });

    if (selectedInvoices?.length) {
      if (files?.length || filesPreview?.length) {
        let paidAmount = selectedInvoices?.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.amount_paid || 0),
          0
        );
        if (paidAmount === Number(paymentDetail?.amount || 0)) {
          isFormValid = true;
        } else {
          errors["amount"] = "Invoice Payments should equal Payment Amount";
          setErrorAlert({
            errorMsg: "Invoice Payments should equal Payment Amount",
            errorType: "warning",
            isOpen: true,
          });
          isFormValid = false;
        }
      } else {
        isFormValid = false;
        errors["unpaid_invoice"] =
          "Please upload the invoices attachment first";
        setErrorAlert({
          errorMsg: "Please upload the invoices attachment first",
          errorType: "warning",
          isOpen: true,
        });
      }
    } else {
      isFormValid = false;
      errors["unpaid_invoice"] = "Please select unpaid invoice first";
      setErrorAlert({
        errorMsg: "Please select unpaid invoice first",
        errorType: "warning",
        isOpen: true,
      });
    }
    return { errors, isFormValid };
  };

  const handleValidate = () => {
    const validationRules = [
      { key: "payment_date", message: "Please enter the payment date" },
      { key: "amount", message: "Please enter the amount" },
      { key: "type", message: "Please select the type of payment" },
      { key: "description", message: "Please enter the invoice description" },
    ];

    const { errors, isFormValid } = validateFields(validationRules);
    setErrors(errors);
    return isFormValid;
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully applied the payment",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleSuccessUpdate = () => {
    setErrorAlert({
      errorMsg: "You have successfully updated the payment",
      errorType: "success",
      isOpen: true,
    });
    handleClose();
    if (handleSuccessCall) {
      handleSuccessCall();
    }
  };
  const handleSubmitFormData = () => {
    const Data = new FormData();
    Data.append("user_id", id);
    Data.append("payment_date", moment(paymentDetail?.payment_date).format());
    Data.append("amount", paymentDetail?.amount);
    Data.append("type", paymentDetail?.type);
    Data.append("description", paymentDetail?.description);
    selectedInvoices?.map((item, index) => {
      Data.append(`invoices[${index}][invoice_id]`, item?.id);
      Data.append(`invoices[${index}][amount_due]`, item?.amount_due || 0);
      Data.append(
        `invoices[${index}][amount_paid]`,
        item?.amount_paid || item?.amount_due || 0
      );
      Data.append(`invoices[${index}][is_new]`, true);
    });
    files.map((item) => {
      Data.append("attachments[]", item.file);
    });

    dispatch(
      createPayment({
        formData: Data,
        handleSuccess,
        handleError,
        selectedInvoices: selectedInvoices?.map((item) => item.id),
      })
    );
  };
  const handleUpdateFormData = () => {
    const Data = new FormData();
    Data.append("payment_date", moment(paymentDetail?.payment_date).format());
    Data.append("amount", paymentDetail?.amount);
    Data.append("type", paymentDetail?.type);
    Data.append("description", paymentDetail?.description);
    selectedInvoices?.map((item, index) => {
      Data.append(`invoices[${index}][invoice_id]`, item?.id);
      Data.append(`invoices[${index}][amount_due]`, item?.amount_due || 0);
      Data.append(
        `invoices[${index}][amount_paid]`,
        item.amount_paid || item?.amount_due || 0
      );
      if (!item.is_new) {
      } else {
        Data.append(`invoices[${index}][is_new]`, true);
      }
    });
    files.map((item) => {
      Data.append("attachments[]", item.file);
    });
    dispatch(
      updatePayment({
        payment_id: prePaymentDetail?.id,
        formData: Data,
        handleSuccess: handleSuccessUpdate,
        handleError,
      })
    );
  };

  const handleApplyPayment = () => {
    if (handleValidate()) {
      handleSubmitFormData();
    }
  };
  const handleUpdatePayment = () => {
    if (handleValidate()) {
      handleUpdateFormData();
    }
  };

  useEffect(() => {
    if (open && id) {
      dispatch(getUnpaidInvoices({ user_id: id }));
    }
  }, [open, id]);

  useEffect(() => {
    if (prePaymentDetail?.id && open) {
      setPaymentDetail({
        payment_date: moment(prePaymentDetail?.payment_date).format(
          "YYYY-MM-DD"
        ),
        amount: prePaymentDetail?.amount,
        type: prePaymentDetail?.type,
        description: prePaymentDetail?.description,
        email_alert: prePaymentDetail?.email_alert || false,
      });
      if (prePaymentDetail?.payment_attachments?.length) {
        setFilesPreview(prePaymentDetail?.payment_attachments);
      }
      if (prePaymentDetail?.payment_invoices?.length) {
        setSelectedInvoices(
          prePaymentDetail?.payment_invoices?.map((item) => ({
            ...item.user_tab_invoice,
            ...item,
            is_new: false,
          }))
        );
      }
    }
  }, [prePaymentDetail?.id, open]);

  const columns = [
    {
      field: "invoice_date",
      headerName: "DATE",
      width: 120,
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.invoice_date).format("L")}
          </Typography>
        );
      },
    },
    { field: "invoice_number", headerName: "INVOICE #", width: 130 },
    { field: "category", headerName: "CATEGORY", minWidth: 120, flex: 1 },
    {
      field: "amount_due",
      headerName: "AMT DUE",
      width: 90,
      renderCell: ({ row }) => {
        return (
          <Box align="center">
            {row.amount_due
              ? NumberFormat({
                  number: Number(row?.amount_due),
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              : "$0.00"}
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerName: "Payment",
      sortable: false,
      width: 150,
      renderCell: ({ row }) => {
        let isSelected = selectedInvoices?.find((item) => item.id === row.id);
        return (
          <Box
            onClick={(e) => e.stopPropagation()}
            align="center"
            sx={{ paddingRight: "10px" }}
          >
            {isSelected ? (
              <TextField
                fullWidth
                size="small"
                value={isSelected?.amount_paid}
                onChange={(e) => {
                  let newInvoices = [...selectedInvoices];
                  let index = newInvoices?.findIndex(
                    (item) => item.id === row.id
                  );
                  newInvoices[index]["amount_paid"] = e.target.value;
                  setSelectedInvoices(newInvoices);
                }}
                onClick={(e) => e.stopPropagation()}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: isSelected?.amount_paid ? (
                    <InputAdornment position="start">$</InputAdornment>
                  ) : null,
                }}
                sx={{
                  borderRadius: "18px",
                  "& fieldset": { border: "none" }, // Removes border
                  backgroundColor: "white", // Removes background
                }}
                type="number"
              />
            ) : (
              <TextField
                fullWidth
                size="small"
                disabled
                value=""
                sx={{
                  borderRadius: "18px",
                  "& fieldset": { border: "none" }, // Removes border
                  backgroundColor: "#f4f4f4",
                }}
              />
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
          "& .MuiPaper-root": { overflow: "hidden" },
        }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.modalHeading}>
            {isUpdate ? "EDIT " : "APPLY "}
            PAYMENT
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "40px 20px 20px 40px" }}>
          <Grid container spacing={3} sx={{ mt: "40px" }}>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Date of Payment
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                type="date"
                value={paymentDetail.payment_date}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "payment_date")
                }
                error={errors.payment_date}
                helperText={errors.payment_date}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Payment Amount
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={paymentDetail.amount}
                onChange={(e) => handleUpdateDetail(e.target.value, "amount")}
                error={errors.amount}
                helperText={errors.amount}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.InputLabel}>
                Payment Type
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={paymentDetail.type}
                onChange={(e) => handleUpdateDetail(e.target.value, "type")}
                error={errors.type}
                helperText={errors.type}
                select
              >
                {/* "type" must be one of [E-Payment App: Zelle, E-Payment App: Venmo, E-Payment App: Cash App, Commission, Check, Card Payment] */}
                <MenuItem value="E-Payment App: Zelle">
                  E-Payment App: Zelle
                </MenuItem>
                <MenuItem value="E-Payment App: Venmo">
                  E-Payment App: Venmo
                </MenuItem>
                <MenuItem value="E-Payment App: Cash App">
                  E-Payment App: Cash App
                </MenuItem>
                <MenuItem value="Commission">Commission</MenuItem>
                <MenuItem value="Check">Check</MenuItem>
                <MenuItem value="Card Payment"> Card Payment </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.InputLabel}>
                Payment Description
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={paymentDetail.description}
                onChange={(e) =>
                  handleUpdateDetail(e.target.value, "description")
                }
                error={errors.description}
                helperText={errors.description}
              />
            </Grid>
          </Grid>
          <Box
            sx={{ display: handleSuccessCall ? "none" : "" }}
            className={classes.unpaidInvoices}
          >
            {" "}
            <InputLabel className={classes.InputLabel2}>
              Select Unpaid Invoices to Apply Payment
            </InputLabel>
            <Box className={classes.invoiceTable}>
              <Box className={classes.tableRoot}>
                {" "}
                <DataGrid
                  rows={
                    unPaidInvoices?.data?.length && isUpdate
                      ? [
                          ...prePaymentDetail?.payment_invoices?.map(
                            (item) => ({
                              ...item.user_tab_invoice,
                              ...item,
                              is_new: false,
                            })
                          ),
                          ...unPaidInvoices?.data,
                        ]?.filter(
                          (item, index, self) =>
                            self?.findIndex((it) => it.id === item.id) == index
                        )
                      : unPaidInvoices?.data?.length
                      ? [...unPaidInvoices?.data]
                      : []
                  }
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  onRowSelectionModelChange={handleSelectionChange}
                  rowSelectionModel={selectedInvoices?.map((item) =>
                    Number(item.id)
                  )}
                  sx={{
                    border: 0,
                    "& .MuiCheckbox-root": {
                      color: "#757575", // Change to your preferred color
                    },
                    "& .MuiCheckbox-root.Mui-checked": {
                      color: "#757575", // Change checked checkbox color
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#F3FFF5 !important", // Light blue selected row
                    },
                    "& .Mui-selected:hover": {
                      backgroundColor: "#daf2de !important", // Slightly darker on hover
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: handleSuccessCall ? "none" : "" }}
            className={classes.attachmentSection}
          >
            <InputLabel className={classes.InputLabel2}>
              Add Attachment
            </InputLabel>
            <Box className={classes.attachments}>
              <Dropzone
                onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()} className={classes.dropzoneSection}>
                    <div>
                      <input {...getInputProps()} />
                      <Typography>
                        Click to Upload or Drag & Drop Your File
                      </Typography>
                    </div>
                  </Box>
                )}
              </Dropzone>
            </Box>

            <Box sx={{ padding: "12px" }}>
              {filesPreview.length ? (
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"flex-start"}
                  flexWrap="wrap"
                >
                  {filesPreview.map((item, index) => (
                    <Box sx={{ position: "relative", mb: "5px" }}>
                      {item.file.slice(17, 20) === "pdf" ? (
                        <a
                          href={item.file_path || item.url}
                          rel="noreferrer"
                          target={"_blank"}
                          style={{ display: "block" }}
                        >
                          <iframe
                            style={{ pointerEvents: "none" }}
                            width="120"
                            height="120"
                            src={item.file_path || item.file}
                          >
                            {" "}
                          </iframe>
                        </a>
                      ) : (
                        <a href={item.url} rel="noreferrer" target={"_blank"}>
                          {" "}
                          <img
                            style={{
                              width: "120px",
                              height: "120px",
                              objectFit: "cover",
                            }}
                            src={item.file_path || item.file}
                            alt={`expense document ${index}`}
                          />
                        </a>
                      )}

                      <HighlightOffRoundedIcon
                        onClick={() => handleDelFile(item)}
                        sx={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              ) : null}
            </Box>
          </Box>

          <Box sx={{ mt: "24px" }}>
            <InputLabel className={classes.InputLabel}>
              Schedule Email Alerts to Agent
            </InputLabel>
            <Box className={classes.selectionBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    checked={paymentDetail?.email_alert}
                    onChange={(e) =>
                      handleUpdateDetail(e.target.checked, "email_alert")
                    }
                  />
                }
                label="Send Payment Alert"
                color="inherit"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {" "}
          <Box className={classes.buttonSection}>
            {isUpdate ? (
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                onClick={handleUpdatePayment}
                loadingPosition="start"
                loading={editPayment.isLoading}
                disabled={editPayment.isLoading}
              >
                Update Payment
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                color="inherit"
                className={classes.createButton}
                onClick={handleApplyPayment}
                loadingPosition="start"
                loading={applyPayment.isLoading}
                disabled={applyPayment.isLoading}
              >
                Apply payment
              </LoadingButton>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Confirmation"
        content="Are you sure you want to delete this attachment?"
        handleConfirm={handleConfirm}
        loading={delAttachment.isLoading}
      />
    </>
  );
}
export default withStyles(styleSheet, { name: "ApplyPaymentStyle" })(
  ApplyPayment
);
