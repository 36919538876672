export const styleSheet = () => ({
  tableRoot: {
    height: "calc(100vh - 140px)",
    width: "100%",
    backgroundColor: "white",
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    // Force hardware acceleration
    transform: 'translate3d(0,0,0)',
    // Ensure proper stacking context
    zIndex: 0,
    
    // Container specific styles
    '& .MuiDataGrid-root': {
      border: 'none',
      height: '100%',
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column'
    },
    
    // Ensure content doesn't collapse
    '& .MuiDataGrid-main': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      minHeight: 0,
      minWidth: 0
    },
    
    // Ensure proper scrollbar display
    '& .MuiDataGrid-virtualScroller': {
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
        display: 'block !important'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#F5F5F5'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888'
      },
      // Ensure proper spacing for horizontal scrollbar
      marginBottom: '56px'
    },
    
    // Footer positioning
    '& .MuiDataGrid-footerContainer': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
      backgroundColor: 'white'
    }
  },
  agentTableHeading: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext !important",
  }
});
