import React, { Fragment, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  Typography,
  Skeleton,
  Grid,
  Paper,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NumberFormat } from "../../../../../utils/numberFormat";
import { useSelector, useDispatch } from "react-redux";
import { DataGridPro, GridRow } from "@mui/x-data-grid-pro";
import moment from "moment";
import ResponseAlert from "../../../../../components/responseAlert";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import TableSkeleton from "../../../../../components/loader/tableLoader.jsx";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import OutsideClickHandler from "react-outside-click-handler";
import ConfirmModal from "../../../../../components/globalModal/ConfirmModal";
import {
  deletePayment,
  getPayments,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import ApplyPayment from "./applyPayment.jsx";
function PaymentsView(props) {
  const { classes, open, setOpen, id } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [openUpdate, setOpenUpdate] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const payments = useSelector((state) => state.agentRoster.AgentTabs.payments);
  const delPayment = useSelector(
    (state) => state.agentRoster.AgentTabs.delPayment
  );
  const handleToggleExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const columns = [
    {
      field: "payment_date",
      headerName: "DATE",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.payment_date).format("L")}
          </Typography>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              background:
                row.status === "pending"
                  ? "#dedede"
                  : row.status === "posted" || row.status === "correction"
                  ? "#499256"
                  : "#d8d8d8",
              color:
                row.status === "posted" || row.status === "correction"
                  ? "white"
                  : "",
            }}
            className={classes.statusChip}
          >
            {row.status || "pending"}
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "type",
      headerName: "TYPE",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              background:
                row.type?.replace("E-Payment App: ", "") === "Zelle" ||
                row.type?.replace("E-Payment App: ", "") === "Venmo" ||
                row.type?.replace("E-Payment App: ", "") === "Cash App"
                  ? "#ffe4ab"
                  : row.type === "Commission"
                  ? "black"
                  : row.type === "Check"
                  ? "#abd9ff"
                  : row.type === "Card Payment"
                  ? "#dbccff"
                  : "#d8d8d8",
              color: row.type === "Commission" ? "white" : "",
            }}
            className={classes.statusChip}
          >
            {row.type?.replace("E-Payment App: ", "")}
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>{row?.description}</Typography>
        );
      },
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "amount",
      headerName: "Payment Amt",
      renderCell: ({ row }) => {
        return (
          <Box align="center">
            {row.amount
              ? NumberFormat({
                  number: Number(row?.amount),
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              : "$0.00"}
          </Box>
        );
      },
      alignItems: "center",
      width: 135,
      editable: false,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      sortable: false,
      align: "center",
      renderCell: ({ row }) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
            position: "relative",
            display: "fex",
          }}
        >
          {expandedRows[row.id] ? (
            <IconButton onClick={() => handleToggleExpand(row.id)} size="small">
              <ExpandLessRoundedIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => handleToggleExpand(row.id)} size="small">
              <ExpandMoreRoundedIcon />
            </IconButton>
          )}
          <Box sx={{ display: row?.status === "paid" ? "none" : "flex" }}>
            <IconButton onClick={(e) => setOpenMenu(row.id)} size="small">
              <MoreVertRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      ),
    },
  ];

  const handleSuccess = () => {
    setOpenDelete(false);
    setPaymentDetail({});
    setErrorAlert({
      errorMsg: "You have successfully deleted the payment",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleConfirm = () => {
    dispatch(
      deletePayment({
        payment_id: paymentDetail?.id,
        amount: paymentDetail.amount,
        handleError,
        handleSuccess,
      })
    );
  };

  return (
    <Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="create-invoice-dialog-title"
        aria-describedby="create-invoice-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "flex-end", padding: "6px" }}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {" "}
          <Grid container spacing={2} sx={{ mt: "-30px" }}>
            <Grid item xs={12} md={4.5}>
              {" "}
              <Card
                className={classes.statCard}
                sx={{ boxShadow: "0px 10px 50px 0px rgba(0, 0, 0, 0.05)" }}
              >
                <Box className={classes.topStatCard}>
                  <Typography className={classes.statCardTitle}>
                    Total Payment Amount
                  </Typography>
                </Box>
                <Typography className={classes.statCardValue}>
                  {payments.isLoading ? (
                    <Skeleton
                      variant="text"
                      sx={{
                        fontSize: "1.5rem",
                        display: "inline-block",
                        width: "150px",
                      }}
                    />
                  ) : payments?.paymentAmount ? (
                    NumberFormat({
                      number: payments?.paymentAmount,
                      maximumFractionDigits: 2,
                      currency: "USD",
                    })
                  ) : (
                    "$0.00"
                  )}
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Box className={classes.PaymentViewSection}>
            <Box className={classes.paymentTableRoot}>
              <DataGridPro
                rows={payments?.data?.length ? payments?.data : []}
                loading={payments.isLoading}
                onRowClick={(data) => {}}
                onRowsScrollEnd={(e) => {
                  if (
                    payments?.count > e.visibleRowsCount &&
                    !payments.isLoading
                  ) {
                    dispatch(
                      getPayments({
                        pageNumber: Number(e.visibleRowsCount / 15) + 1,
                        pageSize: 15,
                        user_id: id,
                      })
                    );
                  }
                }}
                columns={columns}
                disableSelectionOnClick
                rowHeight={56}
                components={{
                  LoadingOverlay: TableSkeleton,
                  Row: (props) => (
                    <Box sx={{ position: "relative", overflow: "visible" }}>
                      <GridRow {...props} />
                      {openMenu === props.rowId ? (
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setOpenMenu(false);
                          }}
                        >
                          <Paper
                            sx={{
                              position: "absolute",
                              top: 52,
                              right: 35,
                              height: "auto",
                              border: "0.1px solid lightgrey",
                              background: "white",
                              width: "180px",
                              zIndex: 99999,
                              "&:before": {
                                content: '""',
                                position: "absolute",
                                top: "-8px", // Move the triangle above the card
                                right: "13px", // Adjust to position at the top-right
                                borderLeft: "8px solid transparent",
                                borderRight: "8px solid transparent",
                                borderBottom: "8px solid lightgrey", // Triangle color
                                zIndex: 1,
                              },
                              "&:after": {
                                content: '""',
                                position: "absolute",
                                top: "-7px", // Slightly below the border for background color
                                right: "14px",
                                borderLeft: "7px solid transparent",
                                borderRight: "7px solid transparent",
                                borderBottom: "7px solid white", // Matches the card background
                                zIndex: 2,
                              },
                            }}
                          >
                            <MenuItem
                              sx={{ zIndex: 99999 }}
                              onClick={() => {
                                setOpenMenu(false);
                                setOpenUpdate(true);
                                setPaymentDetail(props?.row);
                              }}
                            >
                              Edit Payment
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpenMenu(false);
                                setPaymentDetail(props?.row);
                                setOpenDelete(true);
                              }}
                            >
                              Delete Payment
                            </MenuItem>
                          </Paper>
                        </OutsideClickHandler>
                      ) : null}
                      {expandedRows[props.rowId] && (
                        <Box
                          sx={{
                            padding: "10px",
                            background: "white",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography className={classes.subHeading01}>
                                Invoices Paid
                              </Typography>
                              <Box className={classes.subTable01}>
                                <DataGridPro
                                  rows={
                                    props?.row?.payment_invoices?.length
                                      ? props?.row?.payment_invoices
                                      : []
                                  }
                                  autoHeight
                                  hideFooter
                                  rowHeight={44}
                                  columns={[
                                    {
                                      field: "invoice_date",
                                      headerName: "INV DATE",
                                      renderCell: ({ row }) => {
                                        return (
                                          <Typography sx={{ fontSize: "14px" }}>
                                            {moment(
                                              row?.user_tab_invoice
                                                ?.invoice_date ||
                                                row?.invoice_date
                                            ).format("L")}
                                          </Typography>
                                        );
                                      },
                                      width: 140,
                                      editable: false,
                                      sortable: true,
                                    },
                                    {
                                      field: "invoice_number",
                                      headerName: "INVOICE #",
                                      renderCell: ({ row }) => {
                                        return (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              gap: "10px",
                                              alignItems: "center ",
                                              color: "#696969",
                                            }}
                                          >
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >
                                              {row?.user_tab_invoice
                                                ?.invoice_number ||
                                                row?.invoice_number}
                                            </Typography>
                                            <IconButton
                                              onClick={() =>
                                                window.open(
                                                  row?.user_tab_invoice
                                                    ?.pdf_path || row?.pdf_path,
                                                  "_blank"
                                                )
                                              }
                                              size="small"
                                            >
                                              {" "}
                                              <DescriptionRoundedIcon fontSize="small" />
                                            </IconButton>
                                          </Box>
                                        );
                                      },
                                      width: 160,
                                      editable: false,
                                      sortable: true,
                                    },
                                    {
                                      field: "Category",
                                      headerName: "Category",
                                      renderCell: ({ row }) => {
                                        return (
                                          <Typography sx={{ fontSize: "14px" }}>
                                            {row?.user_tab_invoice?.category ||
                                              row?.category}
                                          </Typography>
                                        );
                                      },
                                      width: 140,
                                      editable: false,
                                      sortable: true,
                                    },
                                    {
                                      field: "Description",
                                      headerName: "Description",
                                      renderCell: ({ row }) => {
                                        return (
                                          <Typography sx={{ fontSize: "14px" }}>
                                            {row?.user_tab_invoice
                                              ?.description || row?.description}
                                          </Typography>
                                        );
                                      },
                                      minWidth: 220,
                                      flex: 1,
                                      editable: false,
                                      sortable: true,
                                    },
                                    {
                                      field: "Amount",
                                      headerName: "Amount",
                                      renderCell: ({ row }) => {
                                        return (
                                          <Box align="center">
                                            {row?.user_tab_invoice
                                              ?.amount_paid || row?.amount_paid
                                              ? NumberFormat({
                                                  number: Number(
                                                    row?.user_tab_invoice
                                                      ?.amount_paid ||
                                                      row?.amount_paid
                                                  ),
                                                  maximumFractionDigits: 2,
                                                  currency: "USD",
                                                })
                                              : "0"}
                                          </Box>
                                        );
                                      },
                                      alignItems: "center",
                                      width: 160,
                                      editable: false,
                                      sortable: true,
                                    },
                                  ]}
                                />
                              </Box>
                            </Grid>
                            {props?.row?.payment_attachments?.length ? (
                              <Grid item xs={12}>
                                <Typography className={classes.subHeading01}>
                                  Attachments
                                </Typography>
                                <Box className={classes.attachments}>
                                  {props?.row?.payment_attachments?.map(
                                    (attachment, index) => (
                                      <Box
                                        className={classes.attachmentsItem}
                                        component={"a"}
                                        href={`${attachment?.file_path}`}
                                        target="_blank"
                                      >
                                        <DescriptionRoundedIcon
                                          sx={{ color: "#696969" }}
                                        />
                                        <Typography
                                          sx={{
                                            color: "#0082F4DE !important",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {attachment.file}
                                        </Typography>
                                      </Box>
                                    )
                                  )}
                                </Box>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  ),
                }}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        title="Delete Confirmation"
        content="Are you sure you want to delete this invoice?"
        handleConfirm={handleConfirm}
        loading={delPayment.isLoading}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <ApplyPayment
        open={openUpdate}
        setOpen={setOpenUpdate}
        prePaymentDetail={paymentDetail}
        isUpdate={true}
        id={id}
      />
    </Fragment>
  );
}

export default PaymentsView;
