import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { DataGridPro, GridRow } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../../../components/loader/tableLoader.jsx";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import moment from "moment";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { NumberFormat } from "../../../../../utils/numberFormat";
import UpdateInvoice from "./createInvoice.jsx";
import OutsideClickHandler from "react-outside-click-handler";
import {
  editInvoice,
  deleteInvoice,
  sendInvoiceAlert,
} from "../../../../../redux/agents/agentRosterDetail/agentTabs";
import { useDispatch, useSelector } from "react-redux";
import ResponseAlert from "../../../../../components/responseAlert";
import DeleteDraft from "./deleteDraft.jsx";
import VoidInvoice from "./voidInvoice.jsx";
import ConfirmModal from "../../../../../components/globalModal/ConfirmModal";

function InvoicesTable(props) {
  const { classes, filters, setFilters, id } = props;
  const [expandedRows, setExpandedRows] = useState({});
  const [invoiceMenu, setInvoiceMenu] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.agentRoster.AgentTabs.invoices);
  const updateInvoice = useSelector(
    (state) => state.agentRoster.AgentTabs.updateInvoice
  );
  const delInvoice = useSelector(
    (state) => state.agentRoster.AgentTabs.delInvoice
  );
  const invoiceAlert = useSelector(
    (state) => state.agentRoster.AgentTabs.invoiceAlert
  );

  const handleToggleExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              background:
                row.status === "outstanding"
                  ? "#feb7b7"
                  : row.status === "draft"
                  ? "black"
                  : row.status === "paid"
                  ? "#a0e9b2"
                  : "#d8d8d8",
              color: row.status === "draft" ? "white" : "",
            }}
            className={classes.statusChip}
          >
            {row.status}
          </Box>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "invoice_date",
      headerName: "Date",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.invoice_date).format("L")}
          </Typography>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "invoice",
      headerName: "INVOICE #",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
              alignItems: "center ",
              color: "#696969",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>
              {row?.invoice_number}
            </Typography>
            <IconButton
              onClick={() => {
                window.open(row.pdf_path || row.pdf_url, "_blank");
              }}
              size="small"
            >
              {" "}
              <DescriptionRoundedIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "category",
      headerName: "Category",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
            {row?.category}
          </Typography>
        );
      },
      width: 180,
      editable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>{row?.description}</Typography>
        );
      },
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "duedate",
      headerName: "Due Date",
      renderCell: ({ row }) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(row?.due_date).format("L")}
          </Typography>
        );
      },
      width: 120,
      editable: false,
      sortable: true,
    },
    {
      field: "amount_due",
      headerName: "Amt due",
      renderCell: ({ row }) => {
        return (
          <Box align="center">
            {row.amount_due &&
            Number(row?.amount_due || 0) - Number(row?.amount_paid || 0) !== 0
              ? NumberFormat({
                  number:
                    Number(row?.amount_due || 0) -
                    Number(row?.amount_paid || 0),
                  maximumFractionDigits: 2,
                  currency: "USD",
                })
              : "$0.00"}
          </Box>
        );
      },
      alignItems: "center",
      minWidth: 100,
      editable: false,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      width: 150,
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
            position: "relative",
            display: "fex",
          }}
        >
          {expandedRows[params.row.id] ? (
            <IconButton
              onClick={() => handleToggleExpand(params.row.id)}
              size="small"
            >
              <ExpandLessRoundedIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => handleToggleExpand(params.row.id)}
              size="small"
            >
              <ExpandMoreRoundedIcon />
            </IconButton>
          )}
          <Box
            onClick={(e) => {
              setInvoiceMenu(true);
              setInvoiceData(params?.row);
            }}
            sx={{
              pointerEvents:
                params?.row?.status === "paid" ||
                params?.row?.status === "voided"
                  ? "none"
                  : "",
            }}
          >
            <IconButton size="small">
              <MoreVertRoundedIcon
                sx={{
                  color:
                    params?.row?.status === "paid" ||
                    params?.row?.status === "voided"
                      ? "lightgrey"
                      : "",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      ),
    },
  ];

  const handleSuccess = () => {
    setOpenConfirm(false);
    setErrorAlert({
      errorMsg: "You have successfully updated the invoice",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };

  const handleConfirmVoid = ({ reason, sendAlert }) => {
    dispatch(
      editInvoice({
        invoice_id: invoiceData?.id,
        schema: {
          status: "voided",
          user_id: id,
          reason_for_voiding: reason,
          email_alert: sendAlert,
        },
        handleSuccess,
        handleError,
      })
    );
  };

  const handleSuccessDel = () => {
    setDeleteConfirm(false);
    setErrorAlert({
      errorMsg: "You have successfully deleted the invoice",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteInvoice({
        invoice_id: invoiceData?.id,
        handleSuccess: handleSuccessDel,
        handleError,
      })
    );
  };

  const handleSuccessAlert = () => {
    setOpenAlert(false);
    setErrorAlert({
      errorMsg: "Alert sent successfully!",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleConfirm = () => {
    dispatch(
      sendInvoiceAlert({
        invoice_id: invoiceData?.id,
        handleSuccess: handleSuccessAlert,
        handleError,
      })
    );
  };

  return (
    <Box className={classes.gridViewSection}>
      {" "}
      <Box className={classes.tableRoot} sx={{ position: "relative" }}>
        <DataGridPro
          rows={invoices?.data?.length ? invoices?.data : []}
          loading={invoices.isLoading}
          onRowClick={(data) => {}}
          onRowsScrollEnd={(e) => {
            if (invoices?.count > e.visibleRowsCount && !invoices.isLoading) {
              setFilters({
                ...filters,
                pageNumber: Number(e.visibleRowsCount / 15) + 1,
                pageSize: 15,
              });
            }
          }}
          columns={columns}
          disableSelectionOnClick
          rowHeight={56}
          getRowHeight={
            (params) => (expandedRows[params.id] ? 56 : null) // Adjust row height when expanded
          }
          components={{
            LoadingOverlay: TableSkeleton,
            Row: (props) => (
              <Box sx={{ position: "relative", overflow: "visible" }}>
                <GridRow {...props} />
                {invoiceData.id === props.rowId && invoiceMenu ? (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setInvoiceMenu(false);
                      setInvoiceData({});
                    }}
                  >
                    <Paper
                      sx={{
                        position: "absolute",
                        top: 52,
                        right: 35,
                        height: "auto",
                        border: "0.1px solid lightgrey",
                        background: "white",
                        width: "220px",
                        zIndex: 99999,
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          top: "-8px", // Move the triangle above the card
                          right: "13px", // Adjust to position at the top-right
                          borderLeft: "8px solid transparent",
                          borderRight: "8px solid transparent",
                          borderBottom: "8px solid lightgrey", // Triangle color
                          zIndex: 1,
                        },
                        "&:after": {
                          content: '""',
                          position: "absolute",
                          top: "-7px", // Slightly below the border for background color
                          right: "14px",
                          borderLeft: "7px solid transparent",
                          borderRight: "7px solid transparent",
                          borderBottom: "7px solid white", // Matches the card background
                          zIndex: 2,
                        },
                      }}
                    >
                      {invoiceData?.status === "outstanding" ? (
                        <List sx={{ width: "220px" }} disablePadding>
                          <ListItem
                            onClick={() => {
                              setInvoiceMenu(false);
                              setOpenConfirm(true);
                            }}
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText primary="Void Invoice" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              setOpenUpdate(true);
                              setInvoiceMenu(false);
                            }}
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText primary="Edit Invoice" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              setInvoiceMenu(false);
                              setOpenAlert(true);
                            }}
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText primary="Send New Invoice Alert" />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      ) : invoiceData?.status === "draft" ? (
                        <List sx={{ width: "220px" }} disablePadding>
                          <ListItem
                            onClick={() => {
                              setOpenUpdate(true);
                              setInvoiceMenu(false);
                            }}
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText primary="Edit Draft" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              setDeleteConfirm(true);
                              setInvoiceMenu(false);
                            }}
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText primary="Delete Draft" />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      ) : null}
                    </Paper>
                  </OutsideClickHandler>
                ) : null}
                {expandedRows[props.rowId] && (
                  <Box
                    sx={{
                      padding: "10px",
                      background: "white",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography className={classes.subHeading}>
                          Charges
                        </Typography>
                        <Box className={classes.subTable}>
                          <DataGridPro
                            rows={
                              props?.row?.charges?.length
                                ? props?.row?.charges
                                : []
                            }
                            hideFooter
                            rowHeight={44}
                            columns={[
                              {
                                field: "quantity",
                                headerName: "Qty",
                                renderCell: ({ row }) => {
                                  return (
                                    <Box className={classes.statusChip}>
                                      {row.quantity}
                                    </Box>
                                  );
                                },
                                width: 80,
                                editable: false,
                                sortable: true,
                              },
                              {
                                field: "Category",
                                headerName: "Category",
                                renderCell: ({ row }) => {
                                  return (
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {row.category}
                                    </Typography>
                                  );
                                },
                                width: 140,
                                editable: false,
                                sortable: true,
                              },
                              {
                                field: "Description",
                                headerName: "Description",
                                renderCell: ({ row }) => {
                                  return (
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {row.description}
                                    </Typography>
                                  );
                                },
                                minWidth: 220,
                                flex: 1,
                                editable: false,
                                sortable: true,
                              },
                              {
                                field: "Amount",
                                headerName: "Amount",
                                renderCell: ({ row }) => {
                                  return (
                                    <Box align="center">
                                      {row?.amount
                                        ? NumberFormat({
                                            number: Number(row?.amount),
                                            maximumFractionDigits: 2,
                                            currency: "USD",
                                          })
                                        : "0"}
                                    </Box>
                                  );
                                },
                                alignItems: "center",
                                width: 160,
                                editable: false,
                                sortable: true,
                              },
                            ]}
                            components={{
                              NoRowsOverlay: () => null, // Hides the "No rows" message
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.subHeading}>
                          Payments
                        </Typography>
                        <Box className={classes.subTable}>
                          <DataGridPro
                            rows={
                              props?.row?.payments?.length
                                ? props?.row?.payments
                                : []
                            }
                            hideFooter
                            rowHeight={44}
                            components={{
                              NoRowsOverlay: () => null, // Hides the "No rows" message
                            }}
                            columns={[
                              {
                                field: "date",
                                headerName: "Date",
                                renderCell: ({ row }) => {
                                  return (
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {moment(
                                        row?.payment?.payment_date
                                      ).format("L")}
                                    </Typography>
                                  );
                                },
                                width: 120,
                                editable: false,
                                sortable: true,
                              },
                              {
                                field: "category",
                                headerName: "Method",
                                renderCell: ({ row }) => {
                                  return (
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {row?.payment?.type}
                                    </Typography>
                                  );
                                },
                                width: 140,
                                editable: false,
                                sortable: true,
                              },
                              {
                                field: "Description",
                                headerName: "Description",
                                renderCell: ({ row }) => {
                                  return (
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {row?.payment?.description}
                                    </Typography>
                                  );
                                },
                                minWidth: 180,
                                flex: 1,
                                editable: false,
                                sortable: true,
                              },
                              {
                                field: "Amount",
                                headerName: "Amount",
                                renderCell: ({ row }) => {
                                  return (
                                    <Box
                                      align="center"
                                      sx={{ color: "#00B82DDE" }}
                                    >
                                      {row?.amount_paid
                                        ? NumberFormat({
                                            number: Number(row?.amount_paid),
                                            maximumFractionDigits: 2,
                                            currency: "USD",
                                          })
                                        : "0"}
                                    </Box>
                                  );
                                },
                                alignItems: "center",
                                width: 160,
                                editable: false,
                                sortable: true,
                              },
                            ]}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ),
          }}
        />
      </Box>
      <UpdateInvoice
        open={openUpdate}
        isUpdate={true}
        setOpen={setOpenUpdate}
        invoiceData={invoiceData}
        id={id}
      />
      <VoidInvoice
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleConfirm={handleConfirmVoid}
        title={`Void Invoice #${invoiceData?.invoice_number}`}
        loading={updateInvoice.isLoading}
      />
      <DeleteDraft
        open={deleteConfirm}
        setOpen={setDeleteConfirm}
        handleConfirm={handleConfirmDelete}
        title={`Delete Invoice #${invoiceData?.invoice_number}`}
        loading={delInvoice.isLoading}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
      <ConfirmModal
        open={openAlert}
        setOpen={setOpenAlert}
        title="Send Confirmation"
        content="Are you sure you want to send the alert against this invoice?"
        handleConfirm={handleConfirm}
        loading={invoiceAlert.isLoading}
      />
    </Box>
  );
}

export default InvoicesTable;
