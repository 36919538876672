import { createSlice } from "@reduxjs/toolkit";
import { getDocsList } from "./getDocList";
import { getSellersAndDeeds } from "./getSellersDeeds";
import { getFolders } from "./getFolders";
import { getFolderForms } from "./getFolderForms";
import { addFolder } from "./addFolder";
import { getPackages } from "./getPackages";
import { getPackageFiles } from "./getPackageFiles";
import { getDocDetails } from "./getDocDetails";
import { getTransactionFolders } from "./getTransactionFolders";
import { addForms } from "./addForm";
const initialState = {
  docs: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  sellersAnsDeeds: {
    isLoading: false,
    data: {},
    allData: {},
    success: false,
    errMsg: null,
  },
  folders: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  folderForms: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  createFolder: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  packages: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  packageFiles: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  docDetail: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  transactionFolders: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  addForms: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
};
const Docs = createSlice({
  name: "Docs",
  initialState,
  extraReducers: {
    // get docs list api
    [getDocsList.pending]: (state) => {
      state.docs.isLoading = true;
      state.docs.errMsg = null;
    },
    [getDocsList.fulfilled]: (state, action) => {
      state.docs.isLoading = false;
      state.docs.data = action.payload;
    },
    [getDocsList.rejected]: (state, action) => {
      state.docs.isLoading = false;
      state.docs.errMsg = action.payload;
    },
    // get sellers and deeds api
    [getSellersAndDeeds.pending]: (state) => {
      state.sellersAnsDeeds.isLoading = true;
      state.sellersAnsDeeds.errMsg = null;
    },
    [getSellersAndDeeds.fulfilled]: (state, action) => {
      state.sellersAnsDeeds.isLoading = false;
      state.sellersAnsDeeds.allData = action.payload.data;
      state.sellersAnsDeeds.data = action.payload?.data?.response?.data;
    },
    [getSellersAndDeeds.rejected]: (state, action) => {
      state.sellersAnsDeeds.isLoading = false;
      state.sellersAnsDeeds.errMsg = action.payload;
    },
    // get all folders
    [getFolders.pending]: (state) => {
      state.folders.isLoading = true;
      state.folders.errMsg = null;
    },
    [getFolders.fulfilled]: (state, action) => {
      state.folders.isLoading = false;
      state.folders.data = action.payload?.data;
    },
    [getFolders.rejected]: (state, action) => {
      state.folders.isLoading = false;
      state.folders.errMsg = action.payload;
    },
    // get  folder forms
    [getFolderForms.pending]: (state) => {
      state.folderForms.isLoading = true;
      state.folderForms.errMsg = null;
    },
    [getFolderForms.fulfilled]: (state, action) => {
      state.folderForms.isLoading = false;
      state.folderForms.data = action.payload?.data;
    },
    [getFolderForms.rejected]: (state, action) => {
      state.folderForms.isLoading = false;
      state.folderForms.errMsg = action.payload;
    },
    // addFolder
    [addFolder.pending]: (state) => {
      state.createFolder.isLoading = true;
      state.createFolder.errMsg = null;
    },
    [addFolder.fulfilled]: (state, action) => {
      state.createFolder.isLoading = false;
      state.createFolder.data = action.payload?.data;
      state.docs.data = [action.payload?.data, ...state.docs.data];
    },
    [addFolder.rejected]: (state, action) => {
      state.createFolder.isLoading = false;
      state.createFolder.errMsg = action.payload;
    },
    // get all packages
    [getPackages.pending]: (state) => {
      state.packages.isLoading = true;
      state.packages.errMsg = null;
    },
    [getPackages.fulfilled]: (state, action) => {
      state.packages.isLoading = false;
      state.packages.data = action.payload?.data;
    },
    [getPackages.rejected]: (state, action) => {
      state.packages.isLoading = false;
      state.packages.errMsg = action.payload;
    },
    //   get Packages Files
    [getPackageFiles.pending]: (state) => {
      state.folderForms.isLoading = true;
      state.folderForms.errMsg = null;
    },
    [getPackageFiles.fulfilled]: (state, action) => {
      state.folderForms.isLoading = false;
      state.folderForms.data = action.payload?.data;
    },
    [getPackageFiles.rejected]: (state, action) => {
      state.folderForms.isLoading = false;
      state.folderForms.errMsg = action.payload;
    },
    //   doc Detail
    [getDocDetails.pending]: (state) => {
      state.docDetail.isLoading = true;
      state.docDetail.errMsg = null;
    },
    [getDocDetails.fulfilled]: (state, action) => {
      state.docDetail.isLoading = false;
      state.docDetail.data = action.payload?.data;
    },
    [getDocDetails.rejected]: (state, action) => {
      state.docDetail.isLoading = false;
      state.docDetail.errMsg = action.payload;
    },
    //   get Transaction folders
    [getTransactionFolders.pending]: (state) => {
      state.transactionFolders.isLoading = true;
      state.transactionFolders.errMsg = null;
    },
    [getTransactionFolders.fulfilled]: (state, action) => {
      state.transactionFolders.isLoading = false;
      state.transactionFolders.data = action.payload?.data;
    },
    [getTransactionFolders.rejected]: (state, action) => {
      state.transactionFolders.isLoading = false;
      state.transactionFolders.errMsg = action.payload;
    },
    //   get Transaction folders
    [addForms.pending]: (state) => {
      state.addForms.isLoading = true;
      state.addForms.errMsg = null;
    },
    [addForms.fulfilled]: (state, action) => {
      state.addForms.isLoading = false;
      state.addForms.data = action.payload?.data;
    },
    [addForms.rejected]: (state, action) => {
      state.addForms.isLoading = false;
      state.addForms.errMsg = action.payload;
    },
  },
});

export default Docs.reducer;
export * from "./getSellersDeeds";
export * from "./getDocList";
export * from "./getFolders";
export * from "./getFolderForms";
export * from "./addFolder";
export * from "./getPackages";
export * from "./getPackageFiles";
export * from "./getDocDetails";
export * from "./getTransactionFolders";
export * from "./addForm";
