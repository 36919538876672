import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { Grid, Container, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../components/shared/loading";
import FormDisplay from "./FormDisplay.js";
import { useParams } from "react-router-dom";
import { getDocDetails } from "../../../redux/docs";
import SubHeader from "./subHeader.jsx";
const EditDocument = (props) => {
  let { classes } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const docDetail = useSelector((item) => item.Docs.docDetail);

  useEffect(() => {
    dispatch(getDocDetails({ id }));
  }, []);

  return (
    <div className={classes.pageRoot}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{ pt: "0 !important" }}>
          <Box sx={{ height: "100vh", width: "100%" }}>
            {docDetail.isLoading ? (
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <Box
                  sx={{
                    p: 8,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loading />
                </Box>
              </div>
            ) : (
              <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
                <SubHeader {...props} />
                <FormDisplay {...props} />
              </Container>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

// export default EditDocument;
export default withStyles(styleSheet, { name: "EditDocumentStyle" })(
  EditDocument
);
