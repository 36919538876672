import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AvatarGroup,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  calculateDueIn,
  statusComparator,
  statusFilterData,
} from "../../helpers";
import {
  getProjectList,
  setProjectFilterStatus,
  getProjectStats,
} from "../../../../redux/projects/projects";
import Avatars from "../../../../components/uiElements/avatar/index";
import DataGridComponent from "../../DataGridComponent";
import StatusComponent from "../../StatusComponent";
import moment from "moment";
import {
  openProjectDetails,
  setSelectedProjectId,
} from "../../../../redux/projects/project";
import { useLocation } from "react-router-dom";
var columnWidth = 0;

const xgriColumns = [
  {
    field: "project",
    headerName: "Project",
    sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    renderCell: (params) => {
      return (
        <div>
          <span
            style={{
              fontWeight: "700",
              display: "block",
              fontSize: "14px",
              lineHeight: "20.02px",
              letterSpacing: "0.5px",
            }}
          >
            {params.value.name}
          </span>
          <span
            style={{
              fontWeight: "400",
              display: "block",
              fontSize: "14px",
              lineHeight: "20.02px",
            }}
          >
            {params.value.address}
          </span>
        </div>
      );
    },
    flex: 1,
    sortable: true,
    filterable: false,
  },
  {
    field: "agent",
    headerName: "Agent",
    sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
    renderCell: (params) => {
      return (
        <ListItem
          alignItems="flex-start"
          sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
        >
          <ListItemAvatar>
            <Avatars
              sx={{
                marginRight: "10px",
                borderRadius: "4px",
                fontSize: "15px",
              }}
              src={params.value.image}
              variant="square"
            >{`${params.value?.first_name?.slice(0, 1) || "N"}${
              params.value?.last_name?.slice(0, 1) || "A"
            }`}</Avatars>
          </ListItemAvatar>
          <ListItemText
            primary={params.value.first_name}
            secondary={
              <React.Fragment>{params.value.last_name}</React.Fragment>
            }
          />
        </ListItem>
      );
    },
    width: columnWidth * 20 + 150,
    sortable: true,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    sortComparator: statusComparator,
    renderCell: (params) => {
      return <StatusComponent height="28px" status={params.value} />;
    },
    flex: 1,
    minWidth: 175,
    sortable: true,
    filterable: true,
  },
  {
    field: "progress",
    headerName: "Progress",
    sortComparator: (v1, v2) =>
      Math.round(parseInt(v1.percentage)) > Math.round(parseInt(v2.percentage)),
    type: "number",
    renderCell: (params) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              sx={{ color: "#000" }}
              variant="determinate"
              value={params.value.percentage}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${Math.round(parseInt(params.value.percentage))}%`}
              </Typography>
            </Box>
          </Box>
          <span style={{ paddingLeft: "5px" }}>{params.value.text}</span>
        </Box>
      );
    },
    flex: 1,
    minWidth: 200,
    sortable: true,
    filterable: false,
  },
  {
    field: "dueIn",
    headerName: "Due In",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    minWidth: 130,
    sortable: false,
    filterable: false,
  },
  {
    field: "teamMembers",
    headerName: "Team Members",
    renderCell: (params) => {
      return (
        <AvatarGroup
          sx={{
            "& .MuiAvatarGroup-root": {
              margin: "0px !important",
            },
            "& .MuiAvatarGroup-avatar": {
              margin: "0px !important",
              marginLeft: "-8px !important",
            },
            "& .MuiAvatar-circular": {
              margin: "-3px -12px!important",
            },
          }}
          max={3}
        >
          {params.value.map((image) => (
            <Avatars
              src={image.src}
              title={image.title}
              nameInitial={image.title}
              sx={{ marginLeft: "-5px" }}
            >{`${params.value?.title?.slice(0, 1) || "N"}${
              params.value?.title?.slice(0, 1) || "A"
            }`}</Avatars>
          ))}
        </AvatarGroup>
      );
    },
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "created",
    headerName: "Created At",
    renderCell: (params) => {
      return <>{moment(params.value || new Date()).format("MM/DD/YY H:MMa")}</>;
    },
    flex: 1,
    sortable: true,
    filterable: false,
  },
];

function AllProjects(props) {
  /**
   * Variables and States
   */
  const { value: selectedTab, due_date_filter, departmentID, status } = props;
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [rows, setRows] = useState([]);
  const [columns] = useState([...xgriColumns]);
  const [dataGridRowHeight] = useState(50);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 20,
    search: "",
  });

  const departments = useSelector((state) => state.project.departments);
  const projects = useSelector((state) => state.project.projects);
  const totalProjects = useSelector((state) => state.project.totalProjects);
  const projectsLoading = useSelector((state) => state.project.projectsLoading);
  const searchText = useSelector((state) => state.project.searchText);
  const projectStats = useSelector((state) => state.project.projectStats);
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenProject = (id) => {
    dispatch(setSelectedProjectId(id));
    dispatch(openProjectDetails(true));
  };

  /**
   * End Region: Helpers
   */
  /**
   * useEffect Hooks
   */

  useEffect(() => {
    if (!projectStats?.isLoading) {
      dispatch(getProjectStats({ departmentID }));
    }
  }, []);

  useEffect(() => {
    if (selectedTab) {
      setFilterModel({
        items: [
          {
            columnField: "status",
            operatorValue: "contains",
            value:
              selectedTab === departments.length + 3
                ? "completed"
                : selectedTab === departments.length + 4
                ? "canceled"
                : "",
          },
        ],
      });

      dispatch(
        setProjectFilterStatus(
          selectedTab === departments.length + 3
            ? "completed"
            : selectedTab === departments.length + 4
            ? "canceled"
            : ""
        )
      );
    }
  }, [selectedTab]);

  useEffect(() => {
    const p = [];
    projects?.map((d, i) => {
      let dueIn = calculateDueIn(d.due_date);
      let teamMembers = [],
        alreadyExist = [];

      d.project_tasks.map((task) => {
        if (task.team_member && !alreadyExist.includes(task.team_member.id)) {
          teamMembers.push({
            src:
              task.team_member?.profile_images?.profile_img_thumbnail || null,
            title: task.team_member.full_name,
          });
          alreadyExist.push(task.team_member.id);
        }
      });

      // saving agent value length to calculate agent column width
      columnWidth =
        d?.agent?.full_name?.length > columnWidth
          ? d?.agent?.full_name?.length
          : columnWidth;
      let totalTasks = d.project_tasks.length;
      let totalTasksCompl = d.project_tasks.filter(
        (tsk) =>
          (tsk.approval_required &&
            (tsk.status === "approved" || tsk.status === "completed")) ||
          (!tsk.approval_required && tsk.status === "completed")
      ).length;
      let project = {
        id: d.id,
        status: d.status,
        project: {
          name: d?.project_name,
          address: d?.title,
        },
        agent: {
          image: d?.agent?.profile_images?.profile_img_thumbnail,
          last_name: d?.agent?.last_name,
          first_name: d?.agent?.first_name,
          full_name: d?.agent?.full_name,
        },
        progress: {
          percentage:
            totalTasksCompl !== 0
              ? parseInt((totalTasksCompl / totalTasks) * 100)
              : 0,
          text: `${totalTasksCompl} of ${totalTasks} Tasks Complete`,
        },
        dueIn,
        teamMembers,
        department: d.department,
        created: d.createdAt,
      };

      p.push(project);
    });
    setRows(p);
  }, [projects]);

  useEffect(() => {
    let newStatus = [];
    let FilterItem = statusFilterData?.find((item) => item.value === status);
    if (FilterItem?.status) {
      newStatus = FilterItem.status;
    } else {
      newStatus = FilterItem?.value ? [`${FilterItem.value}`] : status;
    }
    dispatch(
      getProjectList({
        ...filters,
        searchText: searchText,
        status: newStatus,
        departmentID,
        due_date_filter,
      })
    );
  }, [filters, filterModel, searchText, status, due_date_filter]);

  useEffect(() => {
    if (state?.project_id) {
      handleOpenProject(state?.project_id);
    }
  }, [state?.project_id]);
  /**
   * End Region: useEffect Hooks
   */
  return (
    <DataGridComponent
      rows={rows}
      rowHeight={dataGridRowHeight}
      columns={columns}
      loading={projectsLoading}
      rowCount={totalProjects}
      hideFooter
      onRowsScrollEnd={(e) => {
        if (totalProjects > e.visibleRowsCount && !projectsLoading) {
          let newStatus = [];
          let FilterItem = statusFilterData?.find(
            (item) => item.value === status
          );
          if (FilterItem.status) {
            newStatus = FilterItem.status;
          } else {
            newStatus = [`${FilterItem.value}`];
          }

          dispatch(
            getProjectList({
              ...filters,
              pageNumber: Number(e.visibleRowsCount / 20) + 1,
              pageSize: 20,
              status: newStatus,
              departmentID,
              due_date_filter,
            })
          );
        }
      }}
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={(fm) => {
        if (fm.items.length > 0) {
          dispatch(setProjectFilterStatus(fm.items[0].value || ""));
          setFilterModel(fm);
        } else {
          dispatch(setProjectFilterStatus(""));
          setFilterModel({ items: [] });
        }
      }}
      onCellClick={(row) => handleOpenProject(row.id)}
    />
  );
}

export default AllProjects;
