import React from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { styled, alpha } from "@mui/material/styles";
// import {
//   Container,
//   Box,
//   AppBar,
//   Toolbar,
//   Button,
//   Typography,
//   InputBase,
// } from "@mui/material";
import { Home, GetApp, Delete, Print } from "@mui/icons-material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  Container,
  Box,
  Button,
  Typography,
  InputBase,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { Home, Folder, Archive } from "@mui/icons-material";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    // vertical padding + font size from searchIcon
    padding: "0 10px 0 10px ",
    width: "289.86px",
    height: "35px",
    transition: theme.transitions.create("width"),
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: "0.15px",
    [theme.breakpoints.up("sm")]: {
      "&:focus": {},
    },
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const HeaderDocs = ({
  classes,
  archiveFunction,
  handleSearchInputChange,
  docName,
  addform,
  printTrigger,
  DownloadTrigger,
  formName,
}) => {
  let navigate = useNavigate();
  return (
    <Box
      className={
        classes.docsAppBar
      } /* sx={{ position: "sticky", top: "65px", zIndex: "100" }} */
    >
      <Container maxWidth="xl">
        <Box className={classes.docsAppBarArea}>
          <Typography className={classes.docsTitle}>
            <Home
              sx={{ cursor: "pointer", fontSize: "30px" }}
              fontSize="large"
              onClick={() => navigate("/docs")}
            />
            <Box
              sx={{
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => navigate("/docs")}
            >
              Docs
            </Box>{" "}
            /<Box sx={{ textTransform: "lowercase !important" }}>{docName}</Box>
          </Typography>
          <Box className={classes.docsAppBarRight}>
            <Box className={classes.docsAppBarRight} sx={{ mr: "30px" }}>
              <Button
                size="small"
                variant="contained"
                startIcon={<FindInPageIcon sx={{ fontSize: "20px" }} />}
                classes={{ root: classes.newfolderButton }}
                onClick={addform}
              >
                Add Forms
              </Button>
              <IconButton onClick={archiveFunction} sx={{ ml: "30px" }}>
                <Box>
                  <ContactPageIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                  <Box className={classes.customAppbarButtonText}> ESign </Box>
                </Box>
              </IconButton>
              <IconButton
                onClick={() => archiveFunction()}
                edge={false}
                sx={{ ml: "30px" }}
              >
                <Box>
                  <Delete sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                  <Box className={classes.customAppbarButtonText}> Delete </Box>
                </Box>
              </IconButton>

              <IconButton
                onClick={() => DownloadTrigger(true)}
                edge={false}
                classes={{ root: classes.customAppbarButton }}
              >
                <Box>
                  <DownloadRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                  <Box className={classes.customAppbarButtonText}>
                    {" "}
                    Download{" "}
                  </Box>
                </Box>
              </IconButton>
              <IconButton
                onClick={() => printTrigger(true)}
                edge={false}
                classes={{ root: classes.customAppbarButton }}
              >
                <Box>
                  <Print sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                  <Box className={classes.customAppbarButtonText}> Print </Box>
                </Box>
              </IconButton>
            </Box>

            <Search>
              <StyledInputBase
                placeholder="Search Folders"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => handleSearchInputChange(e)}
              />
            </Search>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default withStyles(styleSheet, { name: "HeaderDocsStyle" })(HeaderDocs);
