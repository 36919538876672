import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Container,
  Skeleton,
  TextField,
  Box,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  calculateDueIn,
  statusComparator,
  statusFilterDataTasks,
} from "../helpers";

import Avatars from "../../../components/uiElements/avatar/index";
import { setSelectedDepartmentID } from "../../../redux/projects/common";
import { getTaskList, getTasksStats } from "../../../redux/projects/tasks";
import DataGridComponent from "../DataGridComponent";
import { openDeliverable } from "../helpers";
import StatusComponent from "../StatusComponent";
import moment from "moment";
import {
  openTaskDetails,
  setSelectedTaskId,
} from "../../../redux/projects/project";
import { useLocation } from "react-router-dom";

var columnWidth = 0;
const TaskStats = [
  {
    title: "Total Tasks",
    bgColor: "#4D4D4D",
    key: "total_count",
    value: "active",
  },
  {
    title: "Queued Tasks",
    bgColor: "#89BDFA",
    key: "queued_count",
    value: "queued",
  },
  {
    title: "Rejected Tasks",
    bgColor: "#B4291F",
    key: "correction_count",
    value: "correction",
  },
  {
    title: "Submitted Tasks",
    bgColor: "#AAC5A7",
    key: "submitted_count",
    value: "submitted",
  },
  {
    title: "Future Tasks",
    bgColor: "#8170D8",
    key: "future_count",
    value: "future",
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0px auto!important",
    height: "calc(100vh - 350px)",
  },
  detailCardSection: {
    justifyContent: "space-between !important",
    display: "flex!important",
    width: "100%!important",
    marginBottom: "40px!important",
    gap: "15px !important",
  },
  detailCard: {
    display: "flex!important",
    justifyContent: "unset!important",
    alignItems: "center!important",
    padding: "0px 19px!important",
    gap: "10px !important",
    height: "100px!important",
    width: "220px!important",
  },
  title: {
    fontSize: "32px!important",
    fontWeight: "400 !important",
    color: "white !important",
  },
  desc: {
    fontSize: "14px!important",
    fontWeight: "400 !important",
    lineHeight: "130%!important",
    color: "white !important",
  },
  avatar: {
    background: "#BDBDBD!important",
    color: "#fff!important",
    marginRight: "10px!important",
  },
  openButton: {
    width: "100%!important",
    border: "1px solid #0B0909!important",
    borderColor: "#0B0909!important",
    color: "#0B0909!important",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
  deliverablesImg: {
    width: "40px",
    height: "50px",
    border: "1px solid #000",
  },
  deliverablesImgContainer: {
    display: "flex",
    gap: "7px",
  },
}));

const xgriColumns = [
  {
    field: "task",
    headerName: "Task",
    // sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    renderCell: (params) => {
      return (
        <div>
          <span
            style={{
              fontWeight: "700",
              display: "block",
              fontSize: "14px",
              lineHeight: "20.02px",
              letterSpacing: "0.5px",
            }}
          >
            {params.value.name}
          </span>
          <span
            style={{
              fontWeight: "400",
              display: "block",
              fontSize: "14px",
              lineHeight: "20.02px",
            }}
          >
            {params.value.title}
          </span>
        </div>
      );
    },
    flex: 1,
    sortable: true,
    filterable: false,
  },
  {
    field: "agent",
    headerName: "Agent",
    sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
    renderCell: (params) => {
      return (
        <ListItem
          alignItems="flex-start"
          sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
        >
          <ListItemAvatar>
            <Avatars
              sx={{ marginRight: "10px", borderRadius: "4px" }}
              src={params.value.image}
              variant="square"
            >{`${params.value.full_name?.slice(0, 1)}${params.value.full_name
              ?.split(" ")[1]
              ?.slice(0, 1)}`}</Avatars>
          </ListItemAvatar>
          <ListItemText
            primary={params.value.first_name}
            secondary={
              <React.Fragment>{params.value.last_name}</React.Fragment>
            }
          />
        </ListItem>
      );
    },
    width: columnWidth * 20 + 200,
    sortable: true,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    // sortComparator: statusComparator,
    // sortComparator: (v1, v2) => {
    //   return (
    //     statusOrder.indexOf(v1.toLowerCase()) -
    //     statusOrder.indexOf(v2.toLowerCase())
    //   );
    // },
    type: "string",
    renderCell: (params) => {
      return (
        <StatusComponent height="28px" status={params.value || "Future"} />
      );
    },
    minWidth: 130,
    filterable: true,
  },
  {
    field: "dueIn",
    headerName: "Due In",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    minWidth: 130,
    sortable: false,
    filterable: false,
  },
  {
    field: "teamMember",
    headerName: "Team Member",
    renderCell: (params) => {
      if (params.value) {
        return (
          <>
            <Avatars
              alt="Team Member"
              src={params.value.src}
              title={params.value.title}
            >
              {`${params.value.title?.slice(0, 1)}${params.value.title
                ?.split(" ")[1]
                ?.slice(0, 1)}`}
            </Avatars>
          </>
        );
      } else {
        return "";
      }
    },
    flex: 1,
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "created",
    headerName: "Created At",
    renderCell: (params) => {
      return <>{moment(params.value || new Date()).format("MM/DD/YY H:MMa")}</>;
    },
    flex: 1,
    sortable: true,
    filterable: false,
  },
];

function TaskGrid(props) {
  /**
   * Variables and States
   */
  const { departmentID, departmentsActiveView, setDepartmentsActiveView } =
    props;
  const [status, setStatus] = React.useState("active");
  const classes = useStyles();
  const dispatch = useDispatch();
  let { state } = useLocation();
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([...xgriColumns]);
  const [dataGridRowHeight] = React.useState(52);

  const tasks = useSelector((state) => state.project.tasks);
  const totalTasks = useSelector((state) => state.project.totalTasks);
  const tasksLoading = useSelector((state) => state.project.tasksLoading);
  const searchText = useSelector((state) => state.project.searchText);
  const tasksStats = useSelector((state) => state.project.tasksStats);
  const isTaskDetailsOpen = useSelector(
    (state) => state.project.isTaskDetailsOpen
  );
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenTask = (id) => {
    dispatch(setSelectedTaskId(id));
    dispatch(openTaskDetails(true));
  };

  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  React.useEffect(() => {
    if (!tasksStats?.isLoading) {
      dispatch(getTasksStats({ departmentID }));
    }
  }, []);

  React.useEffect(() => {
    if (departmentID) dispatch(setSelectedDepartmentID(departmentID));
    let newStatus = [];
    let FilterItem = statusFilterDataTasks?.find(
      (item) => item.value === status
    );
    if (FilterItem.status) {
      newStatus = FilterItem.status;
    } else {
      newStatus = [`${FilterItem.value}`];
    }
    dispatch(
      getTaskList({
        departmentID,
        status: newStatus,
        pageNumber: 1,
        pageSize: 20,
      })
    );
  }, [searchText, status]);

  React.useEffect(() => {
    const t = [];
    tasks?.map((d, i) => {
      let dueIn = calculateDueIn(d?.project?.due_date);

      let teamMember = null;

      if (d.team_member) {
        teamMember = {
          src: d.team_member.profile_images
            ? d.team_member.profile_images.profile_img_thumbnail
            : null,
          title: d.team_member.full_name,
        };
      }

      // saving agent value length to calculate agent column width
      columnWidth =
        d?.agent?.full_name?.length > columnWidth
          ? d?.agent?.full_name?.length
          : columnWidth;
      let task = {
        id: d.id,
        projectID: d.project_id,
        agentID: d.agent_id,
        status: d.status,
        agent: {
          image: d?.agent?.profile_images?.profile_img_thumbnail || null,
          last_name: d?.agent?.last_name,
          first_name: d?.agent?.first_name,
          full_name: d?.agent?.full_name,
        },
        task: {
          name: d?.task_name,
          title: d.project?.title || "",
        },
        attachments: d.task_deliverables || [],
        dueIn,
        teamMember,
        created: d.createdAt,
      };

      t.push(task);
    });
    setRows(t);

    let cols = [...columns];
    // Adding action colum to grid only when projects are available in the state
    if (cols.length === 5 && tasks.length > 0) {
      // dynamic agent column width
      cols.map((col) => {
        if (col.field === "agent") {
          col.width = columnWidth * 9 + 50;
        }

        return col;
      });

      cols.push({
        field: "attachments",
        headerName: "Attachments",
        sortable: false,
        renderCell: (params) => {
          return (
            <div className={classes.deliverablesImgContainer}>
              {params.value.map((file) => (
                <img
                  onClick={() => openDeliverable(file)}
                  className={classes.deliverablesImg}
                  alt="task_deliverable"
                  src={file.thumbnail}
                />
              ))}
            </div>
          );
        },
        flex: 1,
      });
    }

    setColumns(cols);
  }, [tasks]);

  React.useEffect(() => {
    if (state?.task_id) {
      handleOpenTask(state?.task_id);
    }
  }, [state?.project_id]);

  /**
   * End Region: useEffect Hooks
   */

  return (
    <>
      <Container className={classes.detailCardSection} maxWidth="lg">
        {tasksStats?.isLoading
          ? TaskStats?.map((project, index) => (
              <Card className={classes.detailCard}>
                <Skeleton style={{ height: "100px", width: "100%" }} />
              </Card>
            ))
          : TaskStats?.map((project, index) => (
              <Card
                className={classes.detailCard}
                sx={{ background: project.bgColor, cursor: "pointer" }}
                onClick={() => setStatus(project.value)}
              >
                <div>
                  <Typography className={classes.title}>
                    {tasksStats?.data?.[project.key] || 0}
                  </Typography>
                  <Typography className={classes.desc}>
                    {project.title}
                  </Typography>
                </div>
              </Card>
            ))}
      </Container>

      <Container className={classes.container} maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: "10px",
            gap: "15px",
          }}
        >
          {departmentID ? (
            <TextField
              select
              classes={{ root: classes.detailInput }}
              value={departmentsActiveView}
              onChange={(e) => setDepartmentsActiveView(e.target.value)}
              variant="outlined"
              sx={{ width: 180, background: "white" }}
              size="small"
            >
              <MenuItem value={"projects"}>Projects</MenuItem>
              <MenuItem value={"tasks"}>Tasks</MenuItem>
            </TextField>
          ) : null}
          <TextField
            select
            classes={{ root: classes.detailInput }}
            label="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            variant="outlined"
            sx={{ width: 200, background: "white" }}
            size="small"
          >
            {statusFilterDataTasks.map((item) => (
              <MenuItem key={item} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <DataGridComponent
          rows={rows}
          rowHeight={dataGridRowHeight}
          columns={columns}
          loading={tasksLoading}
          rowCount={totalTasks}
          hideFooter
          // initialState={{
          //   sorting: {
          //     sortModel: [
          //       { field: "status", sort: "asc" },
          //       { field: "created", sort: "desc" },
          //     ],
          //   },
          // }}
          onRowsScrollEnd={(e) => {
            if (
              totalTasks > e.visibleRowsCount &&
              !tasksLoading &&
              !isTaskDetailsOpen
            ) {
              let newStatus = [];
              let FilterItem = statusFilterDataTasks?.find(
                (item) => item.value === status
              );
              if (FilterItem.status) {
                newStatus = FilterItem.status;
              } else {
                newStatus = [`${FilterItem.value}`];
              }

              dispatch(
                getTaskList({
                  departmentID: props.departmentID,
                  status: newStatus,
                  pageNumber: Math.ceil(Number(e.visibleRowsCount / 20) + 1),
                  pageSize: 20,
                })
              );
            }
          }}
          filterMode="server"
          onCellClick={(row) => handleOpenTask(row.id)}
        />
      </Container>
    </>
  );
}
export default TaskGrid;
