import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../utils/getData";
import { getDocDetails } from "../../../redux/docs/getDocDetails.js";
import DosPageSkelton from "../skeltons/dosPageSketon";
import { serverCall } from "../../../redux/calls/serverCall";

const FormDisplay = ({ classes, getFormData, formsData }) => {
  const { id } = useParams();
  const documentID = id;
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { formId, fromDataOld } = state;
  const [activePreview, setActivePreview] = useState(-1);
  const [isActiveId, setIsActiveId] = useState(false);
  const [formAttachments, setFormAttachments] = useState({});
  const [attachmentPreview, setAttachmentPreview] = useState([]);
  const [orderDocument, setOrderDocument] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [bindFieldsCount, setbindFieldsCount] = useState(0);
  const [disable, setDisable] = useState(0);
  const [formData, setFormData] = useState(fromDataOld);
  const [expandedTab, setExpandedTab] = useState([0]);

  const docDetail = useSelector((item) => item.Docs.docDetail);

  const getAttachmentData = async (id) => {
    let url = `${process.env.REACT_APP_BASE_URL}api/docs/folder/form/${id}`;
    const result = await getData(url);
    if (result) {
      if (result.data) {
        setFormAttachments(result.data?.pdf_form || {});
        setFormValues(result.data?.form_values);
        setOrderDocument(result?.data);
      }
    }
  };
  useEffect(() => {
    // console.log(formsData, "formsData");
    setFormData(formsData);
    if (formsData?.length && !formsData?.find((item) => item.id === formId)) {
      getAttachmentData(formsData[0].id);
    }
  }, [formsData]);

  const handleInputChange = async (e) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folder/form/field/`;

    if (
      e.target.value !== "" &&
      !formValues?.filter(
        (field) =>
          field.id.toString() === e.target.id && field.value === e.target.value
      ).length
    ) {
      const payload = { value: e.target.value };
      await serverCall("put", url + e.target.id, payload);
      await dispatch(getDocDetails(documentID)).then((res) => {
        if (res?.payload?.status === 200) {
          setFormData(res.payload.data.forms);
          if (getFormData) {
            getFormData(formData);
          }
        }
      });
      const oldFormValues = [...formValues];
      const newFormValues = oldFormValues.map((val) =>
        val.id.toString() === e.target.id
          ? { ...val, value: e.target.value }
          : val
      );
      setFormValues(newFormValues);
    }
  };

  const handleCheckboxChange = async (e) => {
    const url = `${process.env.REACT_APP_BASE_URL}api/docs/folder/form/field/`;
    const payload = { checked: e.target.checked };
    await serverCall("put", url + e.target.id, payload);
    const oldFormValues = [...formValues];
    const newFormValues = oldFormValues.map((val) =>
      val.id.toString() === e.target.id
        ? { ...val, checked: e.target.checked }
        : val
    );
    setFormValues(newFormValues);
  };

  const addFields = (data, index) => {
    var countField = 0;
    if (data?.pdf_png) {
      const pdfDoc = data?.pdf_png;
      var canvas = document.getElementById(`myCanvas${index}`);
      canvas.style.width = "100%";
      var ctx = canvas.getContext("2d");
      loadImage(pdfDoc.file, canvas, ctx, index, data).then((count) => {
        setbindFieldsCount(count);
      });
    }
    return countField;
  };

  const loadImage = (src, canvas, ctx, index, data) =>
    new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        if (data?.form_values?.length) {
          Promise.all(
            data.form_values.map((field) => {
              addInput(
                field,
                canvas.offsetLeft,
                canvas.offsetTop + canvas.height,
                index
              );
            })
          ).then(() => {
            resolve(data.form_values.length);
          });
        }
      };
      img.onerror = reject;
      img.src = src;
    });

  const addInput = (field, offsetWidth, offsetHeight, index) => {
    let fieldVal = field?.pdf_form_field;

    let input = document.createElement("input");
    let xCo = parseFloat(fieldVal?.x_pos_per);
    let yCo = parseFloat(fieldVal?.y_pos_per);
    let width = parseFloat((fieldVal?.width / 612) * 100);
    let height = parseFloat((fieldVal?.height / 792) * 100);

    input.type = fieldVal?.type;
    input.name = fieldVal?.name;
    input.id = field?.id;
    if (fieldVal?.type === "text") {
      input.value = field?.value;
      input.style.width = width + "%";
      input.style.height = height + "%";
    } else if (fieldVal?.type === "checkbox") {
      input.checked = field?.checked;
      input.style.transform = "scale(1.2)";
    }

    input.style.position = "absolute";
    input.style.left = xCo + "%";
    input.style.bottom = yCo + "%";
    input.style.fontSize = 1 + "vw";
    input.style.backgroundColor = "#ccd7fd";
    input.style.border = "0";

    document.getElementById(`fieldWrap${index}`).appendChild(input);
  };

  const resizeInput = (field, offsetWidth, offsetHeight) => {
    let fieldVal = field?.pdf_form_field;

    let input = document.getElementById(field?.id);
    let xCo = parseFloat(fieldVal?.x_pos_per);
    let yCo = parseFloat(fieldVal?.y_pos_per);
    // let height = parseFloat(fieldVal?.height);
    if (input?.style) {
      input.style.left = xCo + "%";
      input.style.bottom = yCo + "%";
    }
  };

  const resizeFields = () => {
    if (attachmentPreview?.length) {
      attachmentPreview.map((item, index) => {
        let canvas = document.getElementById(`myCanvas${index}`);
        if (item?.form_values?.length) {
          item.form_values.map((field) => {
            resizeInput(
              field,
              canvas.offsetLeft,
              canvas.offsetTop + canvas.height
            );
          });
        }
      });
    }
  };

  useEffect(() => {
    if (formAttachments?.pngs?.length) {
      let previewArray = [];
      let previewData = { pdf_png: formAttachments?.pngs[0] };
      !isActiveId && setActivePreview(formAttachments?.pngs[0]?.id);
      if (activePreview !== -1) {
        previewData = {
          pdf_png: formAttachments?.pngs.filter(
            (png) => png.id === activePreview
          )?.[0],
        };
        setIsActiveId(false);
      }

      if (activePreview === -1) {
        setActivePreview(formAttachments?.pngs[0]?.id);
      }
      formAttachments?.pngs?.map((item) => {
        let fieldVal = [];
        if (formValues.length) {
          formValues.map((formVal) => {
            if (formVal?.pdf_form_field?.page_number === item?.page_number)
              fieldVal.push(formVal);
          });
        }
        let obj = {
          pdf_png: item,
          form_values: fieldVal,
          form_number: formAttachments.form_number,
        };
        previewArray.push(obj);
      });
      setAttachmentPreview(previewArray);
    }
  }, [formAttachments, activePreview]);

  useEffect(() => {
    if (attachmentPreview?.length) {
      attachmentPreview.map((item, index) => {
        addFields(item, index);
      });
    }
  }, [attachmentPreview]);

  useEffect(() => {
    getAttachmentData(formId);
  }, [formId]);

  useEffect(() => {
    let order = 0;
    formData?.form_fields?.map((value, index) => {
      if (value?.id === orderDocument?.id) {
        order = index;
      }
    });
    setDisable(order);
  }, [formData, orderDocument]);

  useEffect(() => {
    if (bindFieldsCount !== 0) {
      document.querySelectorAll("input").forEach((el) => {
        if (el.type === "text") {
          el.addEventListener("blur", handleInputChange);
        } else if (el.type === "checkbox") {
          el.addEventListener("change", handleCheckboxChange);
        }
      });

      return () => {
        document.querySelectorAll("input").forEach((el) => {
          if (el.type === "text") {
            el.removeEventListener("blur", handleInputChange);
          } else if (el.type === "checkbox") {
            el.removeEventListener("change", handleCheckboxChange);
          }
        });
      };
    }
  }, [bindFieldsCount, formValues]);

  useEffect(() => {
    window.addEventListener("resize", resizeFields);
    window.addEventListener("scroll", resizeFields);
    return () => {
      window.removeEventListener("resize", resizeFields);
      window.removeEventListener("scroll", resizeFields);
    };
  }, [attachmentPreview]);

  const handleChangePreview = (index) => {
    // document.querySelectorAll("input").forEach((el) => el.remove());
    Array.from(Array(200).keys()).map((item) => {
      const myNode = document.getElementById(`fieldWrap${item}`);
      if (myNode) {
        myNode.innerHTML = "";
      }
    });
    setFormValues(formData[index]?.form_values);
    setOrderDocument(formData[index]);
    // setActivePreview(id);
    // setIsActiveId(true);
  };

  const attachmentLists = () => {
    return (
      <Box className={classes.tabBarArea}>
        {docDetail?.data?.forms?.map((value, index) => (
          <Accordion
            expanded={expandedTab.includes(index)}
            className={classes.mAccordianColor}
            square
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header p-0"
              onClick={() => {
                let expandedTab_new = [...expandedTab];
                let checkIndex = expandedTab_new.indexOf(index);
                if (checkIndex > -1) {
                  expandedTab_new.splice(checkIndex, 1);
                  setExpandedTab(expandedTab_new);
                } else {
                  expandedTab_new.push(index);
                  setExpandedTab(expandedTab_new);
                }
                // handlePerticularAttachment(value);
              }}
            >
              {/* let aa="https://d1mze41a50g03f.cloudfront.net/docs-pdfs/2_F104.pdf"
              // https://d1mze41a50g03f.cloudfront.net/pg_2/docs-pdfs/5_F113.jpg
              //  */}
              <div>{value?.forms_data?.name}</div>
            </AccordionSummary>
            <AccordionDetails className={classes.mAccordianDetails}>
              {Array.from(Array(value?.forms_data?.total_pages).keys())?.map(
                (attachment, index1) => (
                  <div className={classes.mAccordianTxt} key={index1}>
                    <img
                      src={`${
                        value?.forms_data?.file?.split("/docs-pdfs")?.[0]
                      }/pg_${index1 + 1}/docs-pdfs${value?.forms_data?.file
                        ?.split("/docs-pdfs")?.[1]
                        ?.replace("pdf", "png")}`}
                      alt=""
                      className={`${classes.mAccordianImage} ${
                        attachment?.id === activePreview ? classes.mActive : ""
                      }`}
                      onClick={() => {
                        document
                          .getElementById(`myCanvas${index1}`)
                          ?.scrollIntoView();
                        if (
                          value.pdf_form.form_number !==
                          attachmentPreview[0].form_number
                        ) {
                          handleChangePreview(index);
                          setFormAttachments(value.pdf_form);
                        }
                      }}
                    />
                    <div>{index1 + 1}</div>
                  </div>
                )
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  const handlePreviousDocument = (id) => {
    Array.from(Array(100).keys()).map((item) => {
      const myNode = document.getElementById(`fieldWrap${item}`);
      if (myNode) {
        myNode.innerHTML = "";
      }
    });
    let order = 0;
    formData.map((value, index) => {
      if (value.id === id) {
        order = index;
      }
    });
    setFormAttachments(formData[order - 1]?.pdf_form);
    setFormValues(formData[order - 1]?.form_values);
    setOrderDocument(formData[order - 1]);
    setDisable(order - 1);
    setExpandedTab([order - 1]);
  };

  const handleNextDocument = (id) => {
    Array.from(Array(100).keys()).map((item) => {
      const myNode = document.getElementById(`fieldWrap${item}`);
      if (myNode) {
        myNode.innerHTML = "";
      }
    });

    let order = 0;
    formData.map((value, index) => {
      if (value.id === id) {
        order = index;
      }
    });
    setFormAttachments(formData[order + 1]?.pdf_form);
    setFormValues(formData[order + 1]?.form_values);
    setOrderDocument(formData[order + 1]);
    setDisable(order + 1);
    setExpandedTab([order + 1]);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  console.log(docDetail, "docDetail");
  return (
    <>
      <div className={classes.mDisplay}>
        <div className={classes.mWidth_15}>{attachmentLists()}</div>
        <div className={classes.mWidth_85}>
          <Box className={classes.transactionDetailSection}>
            <Typography className={classes.transactionDetailHead}>
              I am working with:{" "}
              <span>
                {docDetail?.data?.representation_side === "buying"
                  ? "Buyers"
                  : "Sellers"}{" "}
              </span>{" "}
              for
              <span> {docDetail?.data?.name}</span> <a> Edit People</a>
            </Typography>
            <Grid container spacing={2} sx={{ mt: "10px" }}>
              <Grid item xs={4}>
                <Typography className={classes.transactionContTop}>
                  Full Legal Names of All buyer(s)
                </Typography>
                <Typography className={classes.transactionContBottom}>
                  {docDetail?.data?.buyers
                    ?.map((item) => `${item.first_name} ${item?.last_name}`)
                    ?.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.transactionContTop}>
                  Full Legal Names of All seller(s)
                </Typography>
                <Typography className={classes.transactionContBottom}>
                  {docDetail?.data?.sellers
                    ?.map((item) => `${item.first_name} ${item?.last_name}`)
                    ?.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.transactionContTop}>
                  Name of Buying Broker/Salesperson
                </Typography>
                <Typography className={classes.transactionContBottom}>
                  {docDetail?.data?.buyer_brokers
                    ?.map((item) => `${item.first_name} ${item?.last_name}`)
                    ?.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.transactionContTop}>
                  Name of Listing Broker/Salesperson
                </Typography>
                <Typography className={classes.transactionContBottom}>
                  {docDetail?.data?.seller_brokers
                    ?.map((item) => `${item.first_name} ${item?.last_name}`)
                    ?.join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <div className={classes.mainStyle}>
            <Button
              className={classes.mButtonStyle}
              disabled={disable <= 0}
              onClick={() => handlePreviousDocument(orderDocument?.id)}
            >
              Previous Document
            </Button>
          </div>
          {attachmentPreview?.length ? (
            attachmentPreview?.map((item, index) => (
              <div className={classes.mPosition}>
                <canvas
                  id={`myCanvas${index}`}
                  className={classes.mCanvas}
                ></canvas>
                <div id={`fieldWrap${index}`} className={classes.mColor}></div>
              </div>
            ))
          ) : (
            <DosPageSkelton />
          )}
          <div className={classes.mainStyle}>
            <Button
              className={classes.mButtonStyle}
              disabled={disable >= formData?.length - 1}
              onClick={() => handleNextDocument(orderDocument?.id)}
            >
              Next Document
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

// export default FormDisplay;
export default withStyles(styleSheet, { name: "FormDisplayStyle" })(
  FormDisplay
);
