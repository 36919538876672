/**
 * Project Actions
 */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getProjectWithoutRefresh = async (id) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/projects/${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      }
    );
    return resp.data.data
      ? resp.data.data.length
        ? resp.data.data[0]
        : resp.data.data
      : resp.data.data;
  } catch (error) {
    return error;
  }
};

// for getting create project modal fields
export const getProjectModalFields = createAsyncThunk(
  "projectModalFields/getProjectModalFields",
  async (data, thunkAPI) => {
    const { deptID, projectID, agent_id } = data;

    try {
      const resp = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }api/projects/modal-fields/${projectID}/${deptID}?agent_id=${
          agent_id || ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for resetting project modal fields
export const setProjectModalFields = createAsyncThunk(
  "projectModalFields/setProjectModalFields",
  async (data, thunkAPI) => {
    return data;
  }
);

// for creating project
export const createProject = createAsyncThunk(
  "project/createProject",
  async (data, thunkAPI) => {
    try {
      var d = JSON.stringify(data);

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}api/projects`,
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: d,
      };

      const resp = await axios(config);
      if (data.handleSuccess) {
        data.handleSuccess();
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        if (data.handleError) {
          data.handleError(error.response.data.message || error.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (data.handleError) {
          data.handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// create project as draft

export const SaveProjectAsDraft = createAsyncThunk(
  "project/draftProject",
  async (data, thunkAPI) => {
    try {
      var d = JSON.stringify(data);

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}api/projects/drafts`,
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: d,
      };

      const resp = await axios(config);
      if (data.handleSuccess) {
        data.handleSuccess();
      }
      return resp.data;
    } catch (error) {
      if (error.response) {
        if (data.handleError) {
          data.handleError(error.response.data.message);
        }
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        if (data.handleError) {
          data.handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
// for setting current page no of project grid
export const setProjectCurrentPageNo = createAsyncThunk(
  "projectCurrentPageNo/setProjectCurrentPageNo",
  async (data, thunkAPI) => {
    return data;
  }
);

// for setting project filter status value
export const setProjectFilterStatus = createAsyncThunk(
  "filter/setProjectFilterStatus",
  async (data, thunkAPI) => {
    return data;
  }
);

// for getting all projects
export const getProjectList = createAsyncThunk(
  "projectList/getProjectList",
  async (data, thunkAPI) => {
    const agent_id = data?.agent_id || undefined;
    const searchText = thunkAPI.getState().project.searchText;
    const filterProject = thunkAPI.getState().project.filterProject;

    try {
      let status = data.status;
      if (filterProject.status.length !== 0) {
        status = [filterProject.status];
      }
      let url = `${
        process.env.REACT_APP_BASE_URL
      }api/projects?status=${JSON.stringify(status)}`;

      if (agent_id) {
        url += `&agent_id=[${agent_id}]`;
      }

      if (searchText?.length > 0) {
        url += `&search=${searchText}`;
      }
      if (data?.departmentID) {
        url += `&department_id=${data.departmentID}`;
      }
      if (data?.due_date_filter) {
        url += `&due_date_filter=${data.due_date_filter}`;
      }

      url += `&pageNumber=${data?.pageNumber || 1}&pageSize=20`;

      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      return { data: resp.data, pageNumber: data?.pageNumber };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting all projects
export const getDraftProjects = createAsyncThunk(
  "projectList/getDraftProjects",
  async (data, thunkAPI) => {
    const searchText = thunkAPI.getState().project.searchText;

    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/projects/drafts?search=${
        searchText || ""
      }&pageNumber=${data?.pageNumber || 1}&pageSize=20`;

      if (data?.departmentID) {
        url += `&department_id=${data.departmentID}`;
      }

      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      return { data: resp.data, pageNumber: data?.pageNumber };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting all projects
export const deleteDraftProject = createAsyncThunk(
  "projectList/deleteDraftProject",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/projects/drafts/${data.id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      if (data?.handleSuccess) {
        data?.handleSuccess();
      }
      return { ...resp.data, id: data.id };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting all kanban projects
export const getKanbanProjectList = createAsyncThunk(
  "projectList/getKanbanProjectList",
  async (data, thunkAPI) => {
    const agent_id = data?.agent_id || undefined;

    const selectedTab = thunkAPI.getState().project.selectedTab;
    const departments = thunkAPI.getState().project.departments;
    const searchText = thunkAPI.getState().project.searchText;
    const pageNo = thunkAPI.getState().project.projectsCurrentPageNo;

    try {
      let status = [];
      if (selectedTab === departments.length + 3) {
        status = ["completed"];
      } else if (selectedTab === departments.length + 4) {
        status = ["canceled"];
      } else {
        status = [
          "future",
          "queued",
          "on hold",
          "correction",
          "working",
          "review",
          "submitted",
          "approved",
          "milestone completed",
        ];
      }

      let url = `${
        process.env.REACT_APP_BASE_URL
      }api/projects?status=${JSON.stringify(status)}`;
      if (agent_id) {
        url += `&agent_id=[${agent_id}]`;
      }

      if (searchText.length > 0) {
        url += `&search=${searchText}`;
      }

      url += `&pageNumber=${pageNo}&pageSize=25`;

      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });

      return { data: resp.data, pageNo };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting open house projects
export const getOpenHouseProjectList = createAsyncThunk(
  "projectList/getOpenHouseProjectList",
  async (data, thunkAPI) => {
    const { status, pageSize, pageNumber, openHouseType } = data;
    try {
      let url = `${
        process.env.REACT_APP_BASE_URL
      }api/projects?status=${JSON.stringify(status)}&search=${
        data?.search
      }&pageNumber=${pageNumber || 1}&pageSize=${
        pageSize || 25
      }&openHouseType=${openHouseType}&type=openHouse`;
      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      return { data: resp.data, pageNumber };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting a particular project in project details dialog
export const getProject = createAsyncThunk(
  "project/getProject",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/projects/${data}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for getting tasks of a project
export const getProjectTasks = createAsyncThunk(
  "project/getProjectTasks",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/projects/${data}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for saving project data fields
export const saveProjectDataFields = createAsyncThunk(
  "projectDataFields/saveProjectDataFields",
  async (data, thunkAPI) => {
    try {
      const { id, value } = data;
      const d = JSON.stringify({
        value: value,
      });
      const config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}api/projects/data-field/${id}`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: d,
      };

      const resp = await axios(config);
      return resp.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// for changing project Status
export const changeProjectStatus = createAsyncThunk(
  "projectStatus/changeProjectStatus",
  async (data, thunkAPI) => {
    try {
      const { projectID, status } = data;
      const config = {
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}api/projects/change-status/${projectID}`,
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          status: status,
        }),
      };
      await axios(config);
      return data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// get project counts
export const getProjectStats = createAsyncThunk(
  "projectList/getProjectStats",
  async (data, thunkAPI) => {
    try {
      let status = [
        "future",
        "queued",
        "on hold",
        "correction",
        "working",
        "review",
        "submitted",
        "approved",
        "milestone completed",
        "pending",
        "ready_to_complete",
      ];
      let url = `${process.env.REACT_APP_BASE_URL}api/projects/counts`;
      if (data?.departmentID) {
        url += `?department_id=${data.departmentID}`;
      }

      const resp = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      return { data: resp.data.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// upload attachment
export const uploadAttachments = createAsyncThunk(
  "projectList/uploadAttachments",
  async (data, thunkAPI) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/projects/draft-offer/upload-attachments`;

      const resp = await axios.post(url, data.formData, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
      return { data: resp.data };
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
