import React from "react";
import { DataGrid } from "@mui/x-data-grid";
const DataGridTable = ({
  rows,
  columns,
  pageSize,
  rowsPerPageOptions,
  checkboxSelection,
  disableSelectionOnClick = true,
  disableColumnMenu = true,
  disableColumnFilter = true,
  disableColumnSelector = true,
  autoHeight = true,
  setSelectedCheckboxIds = true,
  handlePageSizeChange = true,
  isLoading = false,
  onSelectionModelChange = (ids) => {
    checkboxSelection
      ? setSelectedCheckboxIds(ids)
      : setSelectedCheckboxIds([]);
  },
}) => {
  return (
    <DataGrid
      rows={rows}
      loading={isLoading}
      columns={columns}
      pageSize={pageSize ? pageSize : 5}
      rowsPerPageOptions={
        rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 15, 20, 25, 50, 100]
      }
      onPageSizeChange={handlePageSizeChange}
      checkboxSelection={checkboxSelection}
      disableSelectionOnClick={disableSelectionOnClick}
      disableColumnMenu={disableColumnMenu}
      disableColumnFilter={disableColumnFilter}
      disableColumnSelector={disableColumnSelector}
      autoHeight={autoHeight}
      onRowSelectionModelChange={onSelectionModelChange}
      components={
        isLoading && {
          NoRowsOverlay: () => {
            "";
          },
        }
      }
    />
  );
};

export default DataGridTable;
